import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import html2canvas from 'html2canvas';

import Input from 'components/input/InputComponent';
import Button from 'components/ButtonComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import {fetchGroups} from 'redux/slices/business/businessSlice';
import {fetchAddresses} from 'redux/slices/inventory/inventorySlice';
import {
	parseFileAndFetchDataAsyncAction,
	setRealizationInitialAmountsAsyncAction
} from 'redux/slices/realizations/realizations-api-actions';
import {
	getRealizationDocumentDate,
	getRealizationDocumentNumber,
	getRealizationHead,
	getRealizationItems
} from 'redux/slices/realizations/selectors';
import {
	resetState,
	setRealizationDocumentNumberAction,
	setRealizationItemsAction
} from 'redux/slices/realizations/realizations';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {sortStringValues} from 'helpers/utils';
import {restrictedGroupsIds} from 'constants/document-data';

import cl from 'styles/pages/signup.module.scss';
import tcl from 'styles/pages/[fieldId].module.scss';
import {toast} from 'react-toastify';
import {AppRoute} from 'constants/routes';

const New = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};

	const {alert, setCrumbs} = useContext(AppContext);

	const userLevel = getUser().accessLevel;

	const head = useSelector(getRealizationHead);
	const realizationItems = useSelector(getRealizationItems);
	const documentNumber = useSelector(getRealizationDocumentNumber);
	const documentDate = useSelector(getRealizationDocumentDate);
	const addressList = useSelector((state) => state.inventory).addresses.data;
	const sortedAddresses = structuredClone(addressList).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });
	const groups = useSelector((state) => state.business).groups.data
    .filter((group) => !restrictedGroupsIds.includes(Number(group.id)));
	
	// Контейнер для сообщения внизу формы
	const [formMessage, setFormMessage] = useState("");
	
	// Состояние формы
	const [form, setForm] = useState({
		file: ""
	});
	
	// Флаг отправки файла
	const [isSended, setIsSended] = useState(false);
	
	// Массивы данных с сервера
	const [total, setTotal] = useState(0);

	// Список уже выбранных наименований
	const itemsNames = structuredClone(realizationItems).map((itm) => itm.name);
	
	// Классы таблицы
	const tableClasses = [tcl.mainTable, tcl.top0, "table", "table-responsive"];
	
	const toScreenShot = useRef();
	
	// Асинхронные данные из Бизнес.ру
	const [plantsNames, setPlantsNames] = useState([]);
	const [plantsArticles, setPlantsArticles] = useState([]);
	
	// Поле сортировки и направление
	const [sorted, setSorted] = useState("name");
	const [sortDir, setSortDir] = useState(true);
	
	// Перенаправление на главную, если нет доступа
	useEffect(() => {
		if(userLevel < 2) router.push("/");
	}, []);

	// Запрос наименований растений
	useEffect(() => {
		axios.get("good/all").then(response => {
			setPlantsNames(response.data.result.goods.sort((a, b) => {
				if(a.name === b.name) return 0;
				else return a.name > b.name ? 1 : -1;
			}));
			setPlantsArticles(response.data.result.articles);
		});
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Отгрузки", url: "/realizations"},
			{name: "Новый документ", url: ""}
		]);
	}, []);
	
	// Подсчет общего кол-ва
	useEffect(() => {
		let totalAll = 0;
		realizationItems.forEach(dt => {
			dt.userPositions.forEach(up => {
				if(!isNaN(parseInt(up.amount))) totalAll += parseInt(up.amount);
			});
		});
		setTotal(totalAll);
	}, [realizationItems]);
	
	// Метод изменения данных в форме
	const changeHandler = (name, data) => {
		const f = structuredClone(form);
		f[name] = data;
		setForm(f);
	}
	
	// Отправка файла на сервер
	const sendFile = async () => {
		if(form.file === ""){
			alert("Выберите файл", "danger");
			return false;
		}
		
		if(isSended) return false;
		setIsSended(true);
		
		// Сборка FormData
		const formData = new FormData();
		formData.append("file", form.file);

		const result = await dispatch(parseFileAndFetchDataAsyncAction(formData));

		if (result.error) {
			toast.error('Ошибка загрузки файла', {
				position: 'bottom-right'
			});
		} else {
			navigate(`${AppRoute.Document.REALIZATIONS}/edit/${result.payload.id}`);
		}
	}

	// Формирование списка адресов для выпадающего списка
	const getDisplayedAddresses = (index) => {
		const selectedAddresses = realizationItems[index].userPositions.map((position) => position.address);
		const displayedAddresses = sortedAddresses.filter((address) => !selectedAddresses.includes(address.value));

		return displayedAddresses;
	};
	
	// Метод изменения наименования
	const changeName = (index, name, value) => {
		if(name === "amounts" && realizationItems[index].name === "") return;
		
		const copy = structuredClone(realizationItems);
		
		// Проверка дублирующей позиции
		let error = false;
		copy.forEach(cp => {
			if(cp[name] === value) error = true;
		});
		
		// Вывод ошибки, если это не группа
		if(error && name !== "b_group"){
			alert("Такой товар уже есть в списке!", "danger");
			copy.splice(index, 1);
		} else copy[index][name] = value;

		// Установка соответствующих значений остальных параметров (группа и артикул/наименование)
		if (name === 'name') {
			const plantArticle = plantsArticles.find((article) => article.good === value);
			copy[index].article = plantArticle.value;
			copy[index].b_group = groups.find((group) => group.id === plantArticle.b_group_id).value;
		}
		if (name === 'article') {
			const plantName = plantsNames.find((plantName) => plantName.article === value);
			copy[index].name = plantName.value;
			copy[index].b_group = groups.find((group) => group.id === plantName.b_group_id).value;
		}
		
		dispatch(setRealizationItemsAction(copy));

		// Если товар выбран (наименование определено), то запрашиваем данные с полей
		if (copy[index].name) {
      dispatch(setRealizationInitialAmountsAsyncAction({
				name: copy[index].name,
				date: documentDate
			}));
    }
	}
	
	// Изменение позиций
	const onChangePos = (index, name, value) => {
		const copy = structuredClone(realizationItems);
		
		// Изменение остатков на полях в таблице
		// if(!isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"])) && name === "amount"){
		// 	if(isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"]))) copy[index.index].amounts[index.ind]["amount"] = 0;
		// 	if(!isNaN(parseInt(copy[index.index].userPositions[index.ind]["amount"])))
		// 		copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) + parseInt(copy[index.index].userPositions[index.ind]["amount"]);
		// 	if(!isNaN(parseInt(value)))
		// 		copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) - parseInt(value);
		// }
		
		// Изменение стоимости услуги или просто позиции
		// if((copy[index.index].isUsl === true && name === "address") || name === "address_amount")
		// 	copy[index.index].amounts[index.ind].address = value;
		// else copy[index.index].userPositions[index.ind][name] = value;

		copy[index.index].userPositions[index.ind][name] = value;
		
		dispatch(setRealizationItemsAction(copy));
	}
	
	// Запись бригадира
	const setEmployee = (name, value) => {
		const copy = structuredClone(realizationItems);
		copy[0].employee = value;
		dispatch(setRealizationItemsAction(copy));
	}
	
	// Скролл вниз при раскрытии списка с растениями внизу страницы
	const scrollPlus = useRef(null);
	const scrollCustomSelect = (index) => {
		if(realizationItems.length - 1 - index < 4)
			scrollPlus.current.scrollIntoView({behavior: "smooth"});
	}
	
	// Новая строка
	const addLine = () => {
		const copy = structuredClone(realizationItems);
		copy.push({
			name: "",
			totalAmount: 0,
			amounts: [{
				amount: "",
				address: ""
			}],
			userPositions: [{
				amount: ""
			}]
		});
		dispatch(setRealizationItemsAction(copy));
		setTimeout(() => {
			scrollCustomSelect(copy.length - 1);
		}, 200);
	}
	
	// Удаление строки
	const deleteLine = (index) => {
		alert("Удалить строку?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					const copy = structuredClone(realizationItems);
					copy[index.index].amounts.splice(index.i, 1);
					copy[index.index].userPositions.splice(index.i, 1);
					if(!copy[index.index].amounts.length) copy.splice(index.index, 1);
					dispatch(setRealizationItemsAction(copy));
					alert("Строка удалена", "success");
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = structuredClone(realizationItems);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		dispatch(setRealizationItemsAction(pl));
		setSorted(name);
	}

	// Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName, selectedGroupId) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = plantsNames
      .filter((plant) => !itemsNames.includes(plant.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (selectedGroupId && groups.length) {
      const filteredByGroupPlants = filteredPlants
        .filter((plant) => plant.b_group_id === selectedGroupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

	useEffect(() => {
		if (!groups.length) {
      dispatch(fetchGroups());
    }
		if (!addressList.length) {
			dispatch(fetchAddresses());
		}
	}, []);

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, []);
	
	return (
		<>
			<div className={tcl.floatPanel + " " + tcl.controlsPanel} style={realizationItems.length === 0 ? {display: "none"} : {}}>
				<Button type="accent" onClick={() => alert("Сохранить?", "default", 10000, [
					{
						text: "Да",
						handler: function(){
							// saveRealization();
						}
					},
					{
						text: "Нет",
						handler: function(){
							alert("", "default", 1);
						}
					}
				])}>Сохранить</Button>
				<span
					className="roundIcon material-icons"
					onClick={() => window.open("/faq/realization", "_blank")}
					title="Инструкция"
				>
					quiz
				</span>
			</div>
			
			<div className={cl.main + " container"} style={realizationItems.length > 0 ? {display: "none"} : {}}>
				<form className={cl.form + " " + cl.withoutLabel}>
					<Input type="file" name="file" value={form.file} setValue={changeHandler}/>
					
					{
						formMessage.length > 1 && (
							<div><p>{formMessage}</p></div>
						)
					}
					
					<div className="row">
						<div className="col-sm-12">
							<Button type="accent" onClick={sendFile} inProcess={isSended}>Отправить файл</Button>
						</div>
					</div>
				</form>
			</div>
			
			<div className="stickyContainer" ref={toScreenShot}>
				<p style={{margin: ".3rem 0 0 .5rem"}}>{head.number}</p>
				<p style={{margin: ".3rem 0 .3rem .5rem"}}>{head.customer}</p>
				
				<table className={tableClasses.join(" ")} style={realizationItems.length === 0 ? {display: "none"} : {}}>
					<thead className={tcl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th colSpan="2" className={tcl.borderNone}>
								Отгрузка (реализация) №&nbsp;
								<Input
									type="text"
									name="documentNumber"
									autocomplete="off"
									placeholder="№"
									value={documentNumber}
									setValue={(name, value) => dispatch(setRealizationDocumentNumberAction(value))}
								/>
							</th>
							<th className={tcl.borderNone}>
								от {documentDate.split(' ')[0]}
							</th>
							<th>
								Бригадир:&nbsp;
								<Input
									type="text"
									name="employee"
									value={realizationItems.length > 0 ? realizationItems[0].employee : ""}
									setValue={setEmployee} placeholder="Бригадир"
									title={realizationItems.length > 0 ? realizationItems[0].employee : ""}
								/>
							</th>
							<th colSpan="3">Текущие остатки в Системе:</th>
							<th rowSpan="3">Кол-во<br/>по заказу</th>
							<th colSpan="2">Переместить на Основной склад</th>
							<th rowSpan="3" className={tcl.borderNone}/>
						</tr>
						<tr>
							<th rowSpan="2">№</th>
							<th
								rowSpan="2"
								className={sorted === "name" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("name")}
							>
								<span>Название</span>
							</th>
							<th
								rowSpan="2"
								className={sorted === "article" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("article")}
							>
								<span>Артикул</span>
							</th>
							<th rowSpan="2">Группа</th>
							<th rowSpan="2">Всего:</th>
							<th colSpan="2">В том числе:</th>
							<th rowSpan="2">Кол-во</th>
							<th rowSpan="2">Со склада</th>
						</tr>
						<tr>
							<th>Кол-во</th>
							<th>Адрес</th>
						</tr>
					</thead>
					<tbody>
						{
							realizationItems.length > 0 && realizationItems.map((line, index) =>
								<Fragment key={index.toString()}>
									{
										line.amounts.map((ln, i) =>
											<tr key={i.toString()} className={line.isError ? tcl.error : ""}>
												{
													i === 0 && (
														<>
															<td rowSpan={line.amounts.length}>{index + 1}</td>
															<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
																{
																	line.name === ""
																		? (
																			<CustomSearchSelect
																				inputName='name'
																				defaultValue={line.name}
																				options={filterSelectComponentOptions('name', line.b_group_id)}
																				onChange={(value) => changeName(index, 'name', value)}
																				placeholder='Наименование'
																			/>
																		)
																		: line.name
																}
															</td>
															<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
																{
																	line.name === ""
																		? (
																			<CustomSearchSelect
																				inputName='article'
																				defaultValue={line.article}
																				options={filterSelectComponentOptions('article', line.b_group_id)}
																				onChange={(value) => changeName(index, 'article', value)}
																				placeholder='Артикул'
																			/>
																		)
																		: line.article
																}
															</td>
															<td rowSpan={line.amounts.length}>
																{
																	line.name === ""
																		? (
																			<CustomSearchSelect
																				inputName='b_group'
																				defaultValue={line.b_group}
																				options={groups}
																				onChange={(value) => changeName(index, 'b_group', value)}
																				placeholder='Группа'
																			/>
																		)
																		: line.b_group
																}
															</td>
															<td rowSpan={line.amounts.length} className={tcl.right}>
																{!line.isUsl && new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
															</td>
														</>
													)
												}
												
												<td className={tcl.right}>
													{!line.isUsl && new Intl.NumberFormat("ru-RU").format(ln.amount)}
												</td>
												<td>{!line.isUsl && ln.address}</td>
												
												{
													i === 0 && (
														<td rowSpan={line.amounts.length} className={tcl.right}>
															{line.orderAmount ? new Intl.NumberFormat("ru-RU").format(line.orderAmount) : 0}
														</td>
													)
												}
												
												<td>
													{
														line.isUsl !== true
															? (
																<Input
																	type="number"
																	name="amount"
																	placeholder="Кол-во"
																	value={line.userPositions[i].amount}
																	setValue={onChangePos} currentIndex={{index: index, ind: i}}
																/>
															)
															: line.userPositions[i].amount
													}
												</td>
												
												<td>
													{
														ln.address ? (
															<span>{ln.address}</span>
														) : (
															<CustomSearchSelect
																inputName='address_amount'
																defaultValue={ln.address}
																options={getDisplayedAddresses(index)}
																onChange={(value) => onChangePos({index: index, ind: i}, 'address_amount', value)}
																placeholder='Адрес'
															/>
														)
													}
												</td>
												
												<td className="iconed">
													<span
														className="roundIcon material-icons"
														onClick={() => deleteLine({index: index, i: i})}
														title="Удалить строку"
													>
														delete_outline
													</span>
												</td>
											</tr>
										)
									}
								</Fragment>
							)
						}
						<tr className="adding" ref={scrollPlus}>
							<td className="iconed">
								<span
									className="roundIcon material-icons"
									onClick={addLine}
									title="Добавить наименование"
								>
									add
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default New;
