export const emptyMaterialLine = {
  name: '',
  article: '',
  b_group: '',
  b_group_id: 0,
  good_id: '',
  totalAmount: 0,
  amounts: [{amount: '', address: ''}],
  userPositions: [{counter: '', amount: ''}],
  measure: '',
  isMaterial: true
};
