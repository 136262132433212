import {DocumentType} from 'constants/document-type';

export const NEW_POSTING_BUTTON_CAPTION = 'Новое оприходование';
export const POSTINGS_ARCHIVE_BUTTON_CAPTION = 'Архив оприходований';

export const NEW_CHARGE_BUTTON_CAPTION = 'Новое списание';
export const CHARGES_ARCHIVE_BUTTON_CAPTION = 'Архив списаний';

export const NEW_SHIFTING_BUTTON_CAPTION = 'Новое перемещение';
export const SHIFTINGS_ARCHIVE_BUTTON_CAPTION = 'Архив перемещений';

export const NEW_INVENTORY_BUTTON_CAPTION = 'Новая инвентаризация';
export const INVENTORIES_ARCHIVE_BUTTON_CAPTION = 'Архив инвентаризаций';

export const NEW_REALIZATION_BUTTON_CAPTION = 'Новая отгрузка';
export const REALIZATIONS_ARCHIVE_BUTTON_CAPTION = 'Архив отгрузок';

export const NEW_SUPPLY_BUTTON_CAPTION = 'Новое поступление';
export const SUPPLIES_ARCHIVE_BUTTON_CAPTION = 'Архив поступлений';

export const NEW_FACTORY_BUTTON_CAPTION = 'Новое производство';
export const FACTORIES_ARCHIVE_BUTTON_CAPTION = 'Архив производств';

export const USER_MANUAL_BUTTON_CAPTION = 'Инструкция';

export const DocumentTypeButtonsCaptionsMap = {
  [DocumentType.CHARGE]: {
    NEW: NEW_CHARGE_BUTTON_CAPTION,
    ARCHIVE: CHARGES_ARCHIVE_BUTTON_CAPTION
  },
  [DocumentType.POSTING]: {
    NEW: NEW_POSTING_BUTTON_CAPTION,
    ARCHIVE: POSTINGS_ARCHIVE_BUTTON_CAPTION
  },
  [DocumentType.SHIFTING]: {
    NEW: NEW_SHIFTING_BUTTON_CAPTION,
    ARCHIVE: SHIFTINGS_ARCHIVE_BUTTON_CAPTION
  },
  [DocumentType.INVENTORY]: {
    NEW: NEW_INVENTORY_BUTTON_CAPTION,
    ARCHIVE: INVENTORIES_ARCHIVE_BUTTON_CAPTION
  },
  [DocumentType.REALIZATION]: {
    NEW: NEW_REALIZATION_BUTTON_CAPTION,
    ARCHIVE: REALIZATIONS_ARCHIVE_BUTTON_CAPTION
  },
  [DocumentType.SUPPLY]: {
    NEW: NEW_SUPPLY_BUTTON_CAPTION,
    ARCHIVE: SUPPLIES_ARCHIVE_BUTTON_CAPTION
  },
  [DocumentType.FACTORY]: {
    NEW: NEW_FACTORY_BUTTON_CAPTION,
    ARCHIVE: FACTORIES_ARCHIVE_BUTTON_CAPTION
  }
};
