import {useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
import DocumentsListItems from 'components/documents-list-items/documents-list-items';
import ListLegend from 'components/list-legend/list-legend';

import {
  fetchDocumentsAsyncAction,
  fetchDocumentsNumbersAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {getDocumentsList} from 'redux/slices/documents/selectors';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

import styles from './styles.module.scss';

const ChargesList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {setCrumbs} = useContext(AppContext);

  const documentsListItems = useSelector(getDocumentsList);

  // Запрос списка документов списания для отрисовки в основном списке документов
  useEffect(() => {
    dispatch(fetchDocumentsAsyncAction({
      isInArchive,
      documentType: DocumentTypeNameMap[DocumentType.CHARGE]
    }));
  }, [dispatch, isInArchive]);

  // Запрос списка номеров документов списания для генерации номера нового документа
  // Запрос учитывает все номера, существующие в БД - и основные, и архивные
  useEffect(() => {
    if (!isInArchive && canUserUpdate) {
      dispatch(fetchDocumentsNumbersAsyncAction(DocumentTypeNameMap[DocumentType.CHARGE]));
    }
  }, []);

  // Запись хлебных крошек
  useEffect(() => {
    const crumbs = isInArchive ? [
      {name: 'Архивы', url: AppRoute.Archive.ALL},
      {name: DocumentTypeNameMap[DocumentType.CHARGE], url: AppRoute.Archive.CHARGES}
    ] : [
      {name: DocumentTypeNameMap[DocumentType.CHARGE], url: AppRoute.Document.CHARGES}
    ];

    setCrumbs(crumbs);
  }, [isInArchive]);

  return (
    <div className={styles.wrapper}>
      <ListLegend documentType={DocumentTypeNameMap[DocumentType.CHARGE]}/>
      {
        !isInArchive && (
          <DocumentsListButtonsBlock documentType={DocumentType.CHARGE}/>
        )
      }
      <DocumentsListItems documentsListItems={documentsListItems}/>
    </div>
  );
};

export default ChargesList;
