import cl from "styles/pages/[fieldId].module.scss";
import Input from "components/input/InputComponent";
import { Fragment } from "react";
import CustomSelect from "../CustomSelect";
import { useNavigate } from "react-router-dom";
import CustomSearchSelect from "components/custom-search-select/custom-search-select";

const FormShiftings = ({
  alert,
  typeNames,
  documentNumber,
  setDocumentNumber,
  documentDate,
  setDocumentDate,
  groups,
  lines,
  setLines,
  emptyLine,
  filterNames,
  filterArticles,
  onChangeName,
  onChangePos,
  addLine,
  scrollPlus,
  provided,
  storage,
  setStorage,
  storages,
  employee,
  setEmployee,
  addressList,
  addressListAll,
  storageChecker,
  sortFn,
  sortName
}) => {
  const navigate = useNavigate();
  const router = {
    pathname: window.location.pathname,
    push: (p) => navigate(p),
    query: { type: new URLSearchParams(window.location.search).get("type") },
  };

  // Классы таблицы
  const tableClasses = [
    cl.mainTable,
    cl.top0,
    cl.overflow,
    "table",
    "table-responsive",
  ];

  // Добавление колонки с пользовательским вводом
  const addColumn = (index) => {
    const copy = Object.assign([], lines);
    copy[index].userPositions.push(
      JSON.parse(JSON.stringify(emptyLine.userPositions[0]))
    );
    copy[index].amounts.push(JSON.parse(JSON.stringify(emptyLine.amounts[0])));
    setLines(copy);
  };

  // Кастомное удаление строки
  const deleteLine = (index) => {
    const copy = Object.assign([], lines);
    copy[index.index].userPositions.splice(index.ind, 1);
    copy[index.index].amounts.splice(index.ind, 1);
    if (copy[index.index].amounts.length === 0) copy.splice(index.index, 1);
    setLines(copy);
  };

  // Запись второго склада
  const setSecondStorage = (name, value) => {
    const copy = Object.assign([], lines);
    copy.map((cp, ind) => {
      copy[ind].toStorage = value;
    });
    setLines(copy);
  };

  return (
    <table className={tableClasses.join(" ")}>
      <thead className={cl.top0 + " theadBordered thead-dark"}>
        <tr>
          <th colSpan="2" className={cl.borderNone}>
            {documentNumber !== "" && (
              <>
                {typeNames[router.query.type]} №&nbsp;
                {lines[0].is_copy === 0 ? (
                  documentNumber
                ) : provided === 1 ? (
                  documentNumber
                ) : (
                  <Input
                    type="text"
                    name="documentNumber"
                    autocomplete="off"
                    placeholder="Номер документа"
                    value={documentNumber}
                    setValue={(name, value) => setDocumentNumber(value)}
                  />
                )}
              </>
            )}
          </th>
          <th className={cl.borderNone + " " + cl.dateInput}>
            {documentNumber !== "" && <>от&nbsp;</>}
            {provided === 0 ? (
              <Input
                type="text"
                name="documentDate"
                autocomplete="off"
                mask="99.99.9999 99:99"
                placeholder="Дата"
                value={documentDate}
                setValue={(name, value) => setDocumentDate(value)}
              />
            ) : (
              documentDate
            )}
          </th>
          <th>
            {lines.length > 0 && lines[0].author && (
              <>
                Автор:
                <br />
                {lines[0].author}
                <br />
              </>
            )}
            Бригадир:
            <br />
            <Input
              type="text"
              name="employee"
              value={employee}
              setValue={setEmployee}
              placeholder="Бригадир"
              title={employee}
            />
          </th>
          <th colSpan="3">Остатки до перемещения:</th>
          <th className={cl.storageFixed}>
            Со склада:
            <br />
            {provided === 0 ? (
              <CustomSearchSelect
                inputName='storage'
                defaultValue={storage}
                options={storages}
                onChange={setStorage}
                placeholder='Склад'
              />
            ) : (
              storage
            )}
          </th>
          <th colSpan="2">
            На склад:
            <br />
            {provided === 0 ? (
              <CustomSearchSelect
                inputName='toStorage'
                defaultValue={lines.length > 0 && lines[0].toStorage}
                options={storages}
                onChange={setSecondStorage}
                placeholder='На склад'
              />
            ) : (
              lines[0].toStorage
            )}
          </th>
          <th colSpan="3">Остатки после перемещения:</th>
          {provided === 0 && (
            <th rowSpan="3" colSpan="2" className={cl.borderNone} />
          )}
        </tr>
        <tr>
          <th rowSpan="2">№</th>
          <th
            rowSpan="2"
            className={sortName === "name" ? "sort sorted" : "sort"}
            onClick={() => sortFn("name")}
          >
            <span>Название</span>
          </th>
          <th
            rowSpan="2"
            className={sortName === "article" ? "sort sorted" : "sort"}
            onClick={() => sortFn("article")}
          >
            <span>Артикул</span>
          </th>
          <th
            rowSpan="2"
            className={sortName === "b_group" ? "sort sorted" : "sort"}
            onClick={() => sortFn("b_group")}
          >
            Группа
          </th>
          <th rowSpan="2">Всего:</th>
          <th colSpan="2">В том числе:</th>
          <th rowSpan="2">Кол-во</th>
          <th rowSpan="2">Кол-во</th>
          <th rowSpan="2">Адрес</th>
          <th rowSpan="2">Всего:</th>
          <th colSpan="2">В том числе:</th>
        </tr>
        <tr>
          <th>Кол-во</th>
          <th>Адрес</th>
          <th>Кол-во</th>
          <th>Адрес</th>
        </tr>
      </thead>
      <tbody>
        {lines.map((line, index) => (
          <Fragment key={index.toString()}>
            {line.amounts.map((ln, i) => (
              <tr key={i.toString()} className={line.isError ? cl.error : ""}>
                {i === 0 && (
                  <>
                    <td rowSpan={line.amounts.length}>{index + 1}</td>
                    <td
                      rowSpan={line.amounts.length}
                      className={cl.name}
                      onClick={storageChecker}
                    >
                      {provided === 0 && line.name === "" ? (
                        <CustomSearchSelect
                          inputName='name'
                          defaultValue={line.name}
                          options={filterNames(line.b_group_id)}
                          onChange={(value) => onChangeName(index, 'name', value)}
                          placeholder='Наименование'
                        />
                      ) : (
                        line.name
                      )}
                    </td>
                    <td rowSpan={line.amounts.length} onClick={storageChecker}>
                      {provided === 0 && line.article === "" ? (
                        <CustomSearchSelect
                          inputName='article'
                          defaultValue={line.article}
                          options={filterArticles(line.b_group_id)}
                          onChange={(value) => onChangeName(index, 'article', value)}
                          placeholder='Артикул'
                        />
                      ) : (
                        line.article
                      )}
                    </td>
                    <td rowSpan={line.amounts.length} onClick={storageChecker}>
                      {provided === 0 && line.b_group === "" ? (
                        <CustomSearchSelect
                          inputName='b_group'
                          defaultValue={line.b_group}
                          options={groups}
                          onChange={(value) => onChangeName(index, 'b_group', value)}
                          placeholder='Группа'
                        />
                      ) : (
                        line.b_group
                      )}
                    </td>
                    <td rowSpan={line.amounts.length} className={cl.right}>
                      {new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
                    </td>
                  </>
                )}

                <td className={cl.right}>
                  {new Intl.NumberFormat("ru-RU").format(ln.amount)}
                </td>
                <td className={cl.address}>{ln.address}</td>

                <td className={cl.right}>
                  {provided === 0 ? (
                    <Input
                      type="number"
                      name="amount_current"
                      placeholder="Кол-во"
                      value={line.userPositions[i].amount_current}
                      setValue={onChangePos}
                      currentIndex={{ index: index, ind: i }}
                    />
                  ) : (
                    new Intl.NumberFormat("ru-RU").format(
                      line.userPositions[i].amount_current
                    )
                  )}
                </td>

                <td className={cl.right}>
                  {provided === 0 ? (
                    <Input
                      type="number"
                      name="amount_after"
                      placeholder="Кол-во"
                      value={line.userPositions[i].amount_after}
                      setValue={onChangePos}
                      currentIndex={{ index: index, ind: i }}
                    />
                  ) : (
                    new Intl.NumberFormat("ru-RU").format(
                      line.userPositions[i].amount_after
                    )
                  )}
                </td>

                <td className={cl.address}>
                  {provided === 0 ? (
                    <CustomSearchSelect
                      inputName='address_after'
                      defaultValue={line.userPositions[i].address_after}
                      options={addressListAll.filter(
                        (adr) => adr.storage === lines[0].toStorage
                      )}
                      onChange={(value) => onChangePos({index: index, ind: i}, 'address_after', value)}
                      placeholder='Адрес'
                      onFocus={() => !lines[0].toStorage ? alert("Выберите на какой склад!", "danger") : null}
                    />
                  ) : (
                    line.userPositions[i].address_after
                  )}
                </td>

                {i === 0 && (
                  <td rowSpan={line.amounts.length} className={cl.right}>
                    {new Intl.NumberFormat("ru-RU").format(
                      line.totalAmount_after
                    )}
                  </td>
                )}

                <td className={cl.right}>
                  {new Intl.NumberFormat("ru-RU").format(ln.amount_after)}
                </td>
                <td className={cl.address}>{ln.address_after}</td>

                {provided === 0 && (
                  <td className="iconed">
                    <span
                      className="roundIcon material-icons"
                      onClick={() => deleteLine({ index: index, ind: i })}
                      title="Удалить строку"
                    >
                      delete_outline
                    </span>
                  </td>
                )}

                {i === 0 && provided === 0 && (
                  <td className="iconed" rowSpan={line.amounts.length}>
                    <span
                      className="roundIcon material-icons"
                      onClick={() => addColumn(index)}
                      title="Добавить строку"
                    >
                      add
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </Fragment>
        ))}
        {provided === 0 && (
          <tr className="adding">
            <td className="iconed">
              <span
                className="roundIcon material-icons"
                onClick={addLine}
                title="Добавить строку"
              >
                add
              </span>
            </td>
          </tr>
        )}
        <tr style={{ height: "300px" }} />
        <tr ref={scrollPlus} />
      </tbody>
    </table>
  );
};

export default FormShiftings;
