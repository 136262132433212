import Modal from 'components/Modal';

import style from './styles.module.scss';

const RealizationCheckValuesModal = ({
  data,
  isActive,
  setIsActive
}) => {
  const handleCopyEmptyLinesButtonClick = () => {
    navigator.clipboard.writeText(Object.keys(data).join(' '));
  };

  const CopyEmptyRowsListButton = () => (
    <span
      className="roundIcon material-icons"
      onClick={handleCopyEmptyLinesButtonClick}
      title="Скопировать строки"
    >
      copy
    </span>
  );

  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        <h3 className={style.title}>
          {'Некорректно заполненные строки: '}
          <CopyEmptyRowsListButton />
        </h3>
        <table className={style.table}>
          <tr className={style.row}>
            <td className={style.cell}>Номер строки</td>
            <td className={style.cell}>Наименование</td>
            {
              Object.values(data).every((item) => (
                !item.amountNotInserted &&
                !item.amountNotNumeric &&
                !item.amountExceedsExistingValue &&
                !item.addressNotSelected &&
                !item.addressDoesNotHaveGood
              )) &&
              Object.values(data).some((item) => item.amountNotEqualsToTotal) ? (
                <>
                  <td className={style.cell}>Общее кол-во по адресам</td>
                </>
              ) : (
                <>
                  <td className={style.cell}>Кол-во</td>
                  <td className={style.cell}>Адрес</td>
                </>
              )
            }
          </tr>
          {
            Object.keys(data).map((itemNumber) => (
              <tr className={style.row}>
                <td className={style.cell}>
                  {`${itemNumber} `}
                </td>
                <td className={style.cell}>
                  {
                    data[itemNumber].line.name || (
                      <>
                        <span>Не выбрано</span><br/>
                      </>
                    )
                  }
                </td>
                {
                  !data[itemNumber].amountNotInserted &&
                  !data[itemNumber].amountNotNumeric &&
                  !data[itemNumber].addressNotSelected &&
                  !data[itemNumber].addressNotInStorage &&
                  data[itemNumber].amountNotEqualsToTotal ? (
                    <td className={style.cell}>
                      <span>Не совпадает с общим отгружаемым</span><br/>
                    </td>
                  ) : (
                    <>
                      <td className={style.cell}>
                        {
                          data[itemNumber].amountNotInserted && (
                            <>
                              <span>Не введено</span><br/>
                            </>
                          )
                        }
                        {
                          data[itemNumber].amountNotNumeric && (
                            <>
                              <span>Нечисловое значение</span><br/>
                            </>
                          )
                        }
                        {
                          data[itemNumber].amountExceedsExistingValue && (
                            <>
                              <span>Превышает фактическое</span><br/>
                            </>
                          )
                        }
                      </td>
                      <td className={style.cell}>
                        {
                          data[itemNumber].addressNotSelected && (
                            <>
                              <span>Не выбран</span><br/>
                            </>
                          )
                        }
                        {
                          data[itemNumber].addressDoesNotHaveGood && (
                            <>
                              <span>Не хранит выбранный товар</span><br/>
                            </>
                          )
                        }
                      </td>
                    </>
                  )
                }
              </tr>
            ))
          }
        </table>
      </div>
    </Modal>
  );
};

export default RealizationCheckValuesModal;
