import {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import Indicator from 'components/Indicator';

import {
  deleteFactoryAsyncAction,
  fetchFactoriesAsyncAction
} from 'redux/slices/factory/factory-api-actions';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';

import {getUser} from 'helpers/storage';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

const FactoriesListItem = ({factory}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  const userLevel = getUser().accessLevel;

  const {alert} = useContext(AppContext);

  const handleShowDetailsPageButtonClick = () => {
    const detailsPageRoute = isInArchive
      ? `${AppRoute.Archive.FACTORIES}/${factory.id}`
      : `${AppRoute.Document.FACTORIES}/${factory.id}`;

    navigate(detailsPageRoute);
  };

  const handleRemoveArchiveFactoryButtonClick = () => {
    const handler = async () => {
      try {
        await dispatch(deleteFactoryAsyncAction(factory.id));
        dispatch(fetchFactoriesAsyncAction(true));
      } catch(error) {
        toast.error('Ошибка удаления!', {
          position: 'bottom-right'
        });
      }
    };

    alert('Удалить архив?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  return (
    <tr>
      <td>
        <span>{factory.document_number}</span>
      </td>
      <td>
        <span>{factory.document_date}</span>
      </td>
      <td>
        <span>{factory.factory_date}</span>
      </td>
      <td>
        <span>{factory.product_name}</span>
      </td>
      <td>
        <span>{factory.storage}</span>
      </td>
      <td>
        <span
          className={`${cl.icon} material-icons`}
          title="Посмотреть"
          onClick={handleShowDetailsPageButtonClick}
        >
          visibility
        </span>
      </td>
      {
        isInArchive && userLevel === 3 ? (
          <td>
            <span
              className={`${cl.icon} ${cl.remove} material-icons`}
              title="Удалить"
              onClick={handleRemoveArchiveFactoryButtonClick}
            >
              close
            </span>
          </td>
        ) : (
          <td>
            <Indicator type={factory.provided === 1 ? 'success' : 'danger'}/>
          </td>
        )
      }
    </tr>
  );
};

export default FactoriesListItem;
