import Modal from 'components/Modal';

import style from './styles.module.scss';

const FactoryCheckValuesModal = ({
  data,
  isActive,
  setIsActive
}) => {
  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        {
          data && data.incorrectLines && Object.keys(data.incorrectLines).length ? (
            <>
              <h5 className={style.title}>
                {'Исходные растения и продукция: '}
              </h5>
              <table className={style.table}>
                <tr className={style.row}>
                  <td className={style.cell}>Номер строки</td>
                  <td className={style.cell}>Наименование</td>
                  <td className={style.cell}>Исходное кол-во</td>
                  <td className={style.cell}>Кол-во продукции</td>
                  <td className={style.cell}>Адрес</td>
                </tr>
                {
                  Object.keys(data.incorrectLines).map((lineNumber) => (
                    data.incorrectLines[lineNumber].incorrectUserPositions ? Object.keys(data.incorrectLines[lineNumber].incorrectUserPositions).map((positionNumber, index) => (
                      <tr>
                        {/* Номер строки и наименование */}
                        {
                          index === 0 && (
                            <>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectLines[lineNumber].incorrectUserPositions).length}
                              >
                                {lineNumber}
                              </td>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectLines[lineNumber].incorrectUserPositions).length}
                              >
                                {data.incorrectLines[lineNumber].plant.name}
                              </td>
                            </>
                          )
                        }

                        {/* Количество исходников */}
                        <>
                          {
                            data.incorrectLines[lineNumber].incorrectUserPositions[positionNumber].amountExceedsExistingValue ? (
                              <td className={style.cell}>Превышает имеющееся (в подстроке {positionNumber})</td>
                            ) : (
                              <td className={style.cell}></td>
                            )
                          }
                        </>

                        {/* Количество продукции */}
                        <>
                          {
                            data.incorrectLines[lineNumber].incorrectUserPositions[positionNumber].amountNotInserted ? (
                              <td className={style.cell}>Не указано (в подстроке {positionNumber})</td>
                            ) : (
                              <td className={style.cell}></td>
                            )
                          }
                        </>

                        {/* Адрес поступления продукции */}
                        {
                          data.incorrectLines[lineNumber].incorrectUserPositions[positionNumber].addressNotSelected && (
                            <td className={style.cell}>Не выбран (в&nbsp;подстроке&nbsp;{positionNumber})</td>
                          )
                        }
                      </tr>
                    )) : (
                      <tr>
                        <td
                          className={style.cell}
                        >
                          {lineNumber}
                        </td>
                        <td
                          className={style.cell}
                        >
                          {
                            data.incorrectLines[lineNumber].sourcePlantNotSelected && !data.incorrectLines[lineNumber].plant.name
                              ? 'Не выбрано'
                              : data.incorrectLines[lineNumber].plant.name
                          }
                        </td>
                        {
                          data.incorrectLines[lineNumber].amountBeforeNotInserted ? (
                            <td
                              className={style.cell}
                            >
                              Не указано
                            </td>
                          ) : (
                            <td
                              className={style.cell}
                            ></td>
                          )
                        }
                        {
                          data.incorrectLines[lineNumber].amountAfterNotInserted ? (
                            <td
                              className={style.cell}
                            >
                              Не указано
                            </td>
                          ) : (
                            <td
                              className={style.cell}
                            ></td>
                          )
                        }
                      </tr>
                    )
                  ))
                }
              </table>
            </>
          ) : null
        }
        {
          data && data.incorrectMaterials && Object.keys(data.incorrectMaterials).length ? (
            <>
              <h5>
                {'Материалы: '}
              </h5>
              <table>
                <tr className={style.row}>
                  <td className={style.cell}>Номер строки</td>
                  <td className={style.cell}>Наименование</td>
                  <td className={style.cell}>Норма расхода на ед.</td>
                  <td className={style.cell}>Кол-во</td>
                </tr>
                {
                  Object.keys(data.incorrectMaterials).map((materialNumber) => (
                    data.incorrectMaterials[materialNumber].incorrectUserPositions ? Object.keys(data.incorrectMaterials[materialNumber].incorrectUserPositions).map((positionNumber, index) => (
                      <tr>
                        {/* Номер строки и наименование */}
                        {
                          index === 0 && (
                            <>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectMaterials[materialNumber].incorrectUserPositions).length}
                              >
                                {materialNumber}
                              </td>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectMaterials[materialNumber].incorrectUserPositions).length}
                              >
                                {data.incorrectMaterials[materialNumber].plant.name}
                              </td>
                              <td
                                className={style.cell}
                                rowSpan={Object.keys(data.incorrectMaterials[materialNumber].incorrectUserPositions).length}
                              ></td>
                            </>
                          )
                        }

                        {/* Количество материала */}
                        {
                          data.incorrectMaterials[materialNumber].incorrectUserPositions[positionNumber].amountExceedsExistingValue ? (
                            <td className={style.cell}>Превышает имеющееся (в подстроке {positionNumber})</td>
                          ) : (
                            <td className={style.cell}></td>
                          )
                        }
                      </tr>
                    )) : (
                      <tr>
                        {/* Номер строки */}
                        <td className={style.cell}>{materialNumber}</td>

                        {/* Наименование */}
                        <td className={style.cell}>
                          {
                            data.incorrectMaterials[materialNumber].materialNotSelected && !data.incorrectMaterials[materialNumber].plant.name
                              ? 'Не выбрано'
                              : data.incorrectMaterials[materialNumber].plant.name
                          }
                        </td>

                        {/* Норма расхода на единицу продукции */}
                        {
                          data.incorrectMaterials[materialNumber].materialRateNotInserted ? (
                            <td
                              className={style.cell}
                            >Не указана</td>
                          ) : (
                            <td
                              className={style.cell}
                            ></td>
                          )
                        }

                        {/* Количество материала */}
                        <td className={style.cell}></td>
                      </tr>
                    )
                  ))
                }
              </table>
            </>
          ) : null
        }
      </div>
    </Modal>
  );
};

export default FactoryCheckValuesModal;
