import {useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import {toast} from 'react-toastify';

import {
  fetchDocumentAsyncAction,
  postDocumentAsyncAction,
  updateDocumentAsyncAction,
  deleteDocumentAsyncAction,
  provideDocumentAsyncAction,
  denyProvidingDocumentAsyncAction,
  provideInBruAsyncAction,
  makeDocumentCorrectionAsyncAction as createDocumentCorrectionAsyncAction,
  copyFormFilesAsyncAction,
} from 'redux/slices/documents/documents-api-actions';
import {resetDocumentAction} from 'redux/slices/documents/documents';
import {
  getDocument,
  getCommentFiles,
  getDoneStatus,
  getProvidedStatus,
  getDocumentsNumbers,
  getNewDocumentDate
} from 'redux/slices/documents/selectors';
import {redirectToRoute} from 'redux/actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {generateDate} from 'helpers/utils';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';
import toastMessageStyles from 'styles/components/toast-message.module.scss';

const DocumentsFloatPanel = ({
  sendCommentFiles,
  setModalActive,
  setIsPreloaderActive,
  setCheckValuesModalData,
  setIsCheckValuesModalActive,
  setCheckShiftingValuesModalData,
  setIsCheckShiftingValuesModalActive,
  addresses
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();

  const LOCATION_REG_EXP = /archive/;
  const CHARGES_LOCATION_REG_EXP = /charges/;
  const POSTINGS_LOCATION_REG_EXP = /postings/;
  const SHIFTINGS_LOCATION_REG_EXP = /shiftings/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);
  const isInCharges = CHARGES_LOCATION_REG_EXP.test(pathName);
  const isInPostings = POSTINGS_LOCATION_REG_EXP.test(pathName);
  const isInShiftings = SHIFTINGS_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert} = useContext(AppContext);

  const document = useSelector(getDocument);
  const provided = !!useSelector(getProvidedStatus);
  const done = !!useSelector(getDoneStatus);
  const documentItems = document.documentItems;
  const documentNumber = document.documentNumber;
  const documentDate = document.documentDate;
  const newDocumentDate = useSelector(getNewDocumentDate);
  const storage = document.storage;
  const employee = document.employee;
  const comment = document.comment;
  const commentFiles = useSelector(getCommentFiles);
  const chargeDocumentsNumbers = useSelector(getDocumentsNumbers);

  // Проверка ввода номера документа
  const checkDocumentNumberInserted = () => {
    if (!documentNumber) {
      toast.error('Укажите номер документа!', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода имени бригадира
  const checkEmployeeInserted = () => {
    if (!employee) {
      toast.error('Укажите бригадира!', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
      return false;
    } else {
      return true;
    }
  };

  // Проверка выбора склада
  const checkStorageSelected = () => {
    if (!storage) {
      toast.error('Выберите склад!', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
      return false;
    } else {
      return true;
    }
  };

  // Валидация введённых данных в строках
  const checkLinesFilledCorrectly = () => {
    // Объект с данными о товарах (строках), в которых допущена ошибка
    // (передаётся в модальное окно со списком товаров с ошибкой в компонент ChargesNew/PostingsNew)
    const incorrectlyFilledLines = {};

    const getSubLine = (subLineNumber) => {
      const SECOND_LINE_REG_EXP = /\d*2$/;
      const THIRD_LINE_REG_EXP = /\d*3$/;
      const isNSecondLine = SECOND_LINE_REG_EXP.test(String(subLineNumber));
      const isNThirdLine = THIRD_LINE_REG_EXP.test(String(subLineNumber));
      const preposition = isNSecondLine ? 'Во' : 'В';
      const lineNumber = isNThirdLine ? `${subLineNumber}-ей` : `${subLineNumber}-ой`;
      const subLineTitle = `${preposition} ${lineNumber} подстроке не выбран адрес`;

      return subLineTitle;
    };

    documentItems.forEach((line, lineIndex) => {
      const lineNumber = lineIndex + 1;
      const userPositions = line.userPositions;
      // const allSubLinesEmpty = userPositions.length > 1 && userPositions.every((userPosition) => !userPosition.amount);
      const allSubLinesEmpty = userPositions.every((userPosition) => !userPosition.amount);

      // Валидация введённых данных для каждой подстроки (поля/площадки)
      userPositions.forEach((userPosition, posIndex) => {
        const addressSelected = !!userPosition.address;
        const shouldAddressNotSelectedErrorBeShown = !addressSelected;

        if (userPosition.amount !== '') {
          const POSITION_AMOUNT_REG_EXP = /^\d+$/;

          // Условия валидации значений
          const isValueNumeric = POSITION_AMOUNT_REG_EXP.test(userPosition.amount);
          const isSpreadPositive = isInCharges && Number(userPosition.amount) <= Number(line.amounts[posIndex].amount);

          // Следует ли показывать соответствующую ошибку
          const shouldValueNotNumericErrorBeShown = !isValueNumeric;
          const shouldNegativeSpreadErrorBeShown = isValueNumeric ? !isSpreadPositive : false;

          // Проверки значений подстрок (по каждой площадке/полю)
          // для отправки в модальное окно со списком некорректно заполненных строк
          if (shouldValueNotNumericErrorBeShown) {
            if (incorrectlyFilledLines[lineNumber]) {
              if (incorrectlyFilledLines[lineNumber].subLines[userPosition.address ? userPosition.address : getSubLine(posIndex + 1)]) {
                incorrectlyFilledLines[lineNumber].subLines[userPosition.address ? userPosition.address : getSubLine(posIndex + 1)].valueNotNumeric = true;
              } else {
                incorrectlyFilledLines[lineNumber].subLines[userPosition.address ? userPosition.address : getSubLine(posIndex + 1)] = {
                  valueNotNumeric: true
                };
              }
            } else {
              incorrectlyFilledLines[lineNumber] = {
                subLines: {
                  [userPosition.address ? userPosition.address : getSubLine(posIndex + 1)]: {
                    valueNotNumeric: true
                  }
                }
              };
            }
          }
          if (shouldNegativeSpreadErrorBeShown && isInCharges) {
            if (incorrectlyFilledLines[lineNumber]) {
              if (incorrectlyFilledLines[lineNumber].subLines[userPosition.address]) {
                incorrectlyFilledLines[lineNumber].subLines[userPosition.address].spreadNegative = true;
              } else {
                incorrectlyFilledLines[lineNumber].subLines[userPosition.address] = {
                  spreadNegative: true
                };
              }
            } else {
              incorrectlyFilledLines[lineNumber] = {
                subLines: {
                  [userPosition.address]: {
                    spreadNegative: true
                  }
                }
              };
            }
          }
        }

        if (shouldAddressNotSelectedErrorBeShown && isInPostings) {
          if (incorrectlyFilledLines[lineNumber]) {
            if (incorrectlyFilledLines[lineNumber].subLines[getSubLine(posIndex + 1)]) {
              incorrectlyFilledLines[lineNumber].subLines[getSubLine(posIndex + 1)].addressNotSelected = true;
            } else {
              incorrectlyFilledLines[lineNumber].subLines[getSubLine(posIndex + 1)] = {
                addressNotSelected: true
              };
            }
          } else {
            incorrectlyFilledLines[lineNumber] = {
              subLines: {
                [getSubLine(posIndex + 1)]: {
                  addressNotSelected: true
                }
              }
            };
          }
        }
      });

      // Хотя бы для одного адреса должно быть указано кол-во прихода
      if (allSubLinesEmpty) {
        incorrectlyFilledLines[lineNumber] = {
          ...incorrectlyFilledLines[lineNumber],
          allSubLinesEmpty: true
        };
      }

      // Если в строке (в товаре) есть ошибка хоть в одной из подстрок,
      // то добавляем данные о товаре к объекту incorrectlyFilledLines
      if (incorrectlyFilledLines[lineNumber]) {
        incorrectlyFilledLines[lineNumber] = {
          ...incorrectlyFilledLines[lineNumber],
          line
        };
      }
    });

    if (Object.keys(incorrectlyFilledLines).length) {
      setCheckValuesModalData(incorrectlyFilledLines);
      setIsCheckValuesModalActive(true);
      return false
    } else {
      return true;
    }
  };

  const validateShiftingItems = () => {
    // type IncorrectLines = {
    //   'Тестовый товар': {
    //     lineNumber: 2,
    //     good: {},
    //     errorAddresses: {
    //       '1В.1': {
    //         amountNotInserted: true,
    //         amountExceedsExistingValue: true,
    //         addressNotSelected: true,
    //         addressNotInStorage: true
    //       }
    //     },
    //     allSubLinesEmpty: true
    //   }
    // };

    const incorrectLines = {};

    documentItems.forEach((documentItem, documentItemIndex) => {
      const existingItemAddresses = documentItem.userPositions.map((position) => position.address);

      // Проверить, все ли поля пустые
      const allSubLinesEmpty = documentItem.userPositions.every((position) => !position.amount_after && !position.address_after);
      if (allSubLinesEmpty) {
        if (incorrectLines[documentItem.name]) {
          incorrectLines[documentItem.name].allSubLinesEmpty = true;
        } else {
          incorrectLines[documentItem.name] = {
            allSubLinesEmpty: true
          };
        }
      }

      // Пройти по amounts и проверить на превышение общего кол-ва в userPositions (amountExceedsExistingValue)
      documentItem.amounts.forEach((amountsItem) => {
        const existingAmount = Number(amountsItem.amount);
        const userPositionsTotalAmount = documentItem.userPositions
          .filter((item) => item.address === amountsItem.address)
          .reduce((res, item) => res + Number(item.amount ? item.amount : 0), 0);

        if (userPositionsTotalAmount > existingAmount) {
          if (incorrectLines[documentItem.name]) {
            if (incorrectLines[documentItem.name].errorAddresses) {
              if (incorrectLines[documentItem.name].errorAddresses[amountsItem.address]) {
                incorrectLines[documentItem.name].errorAddresses[amountsItem.address].amountExceedsExistingValue = true;
              } else {
                incorrectLines[documentItem.name].errorAddresses[amountsItem.address] = {
                  amountExceedsExistingValue: true
                };
              }
            } else {
              incorrectLines[documentItem.name].errorAddresses = {
                [amountsItem.address]: {
                  amountExceedsExistingValue: true
                }
              };
            }
          } else {
            incorrectLines[documentItem.name] = {
              errorAddresses: {
                [amountsItem.address]: {
                  amountExceedsExistingValue: true
                }
              }
            };
          }
        }
      });

      // Проверить: если есть адрес, но нет кол-ва (amountNotInserted)
      // Проверить: если есть кол-во, но нет адреса (addressNotSelected)
      documentItem.userPositions.forEach((position) => {
        if (!position.amount_after && position.address_after) {
          if (incorrectLines[documentItem.name]) {
            if (incorrectLines[documentItem.name].errorAddresses) {
              if (incorrectLines[documentItem.name].errorAddresses[position.address]) {
                incorrectLines[documentItem.name].errorAddresses[position.address].amountNotInserted = true;
              } else {
                incorrectLines[documentItem.name].errorAddresses[position.address] = {
                  amountNotInserted: true
                };
              }
            } else {
              incorrectLines[documentItem.name].errorAddresses = {
                [position.address]: {
                  amountNotInserted: true
                }
              };
            }
          } else {
            incorrectLines[documentItem.name] = {
              errorAddresses: {
                [position.address]: {
                  amountNotInserted: true
                }
              }
            };
          }
        }
        if (position.amount_after && !position.address_after) {
          if (incorrectLines[documentItem.name]) {
            if (incorrectLines[documentItem.name].errorAddresses) {
              if (incorrectLines[documentItem.name].errorAddresses[position.address]) {
                incorrectLines[documentItem.name].errorAddresses[position.address].addressNotSelected = true;
              } else {
                incorrectLines[documentItem.name].errorAddresses[position.address] = {
                  addressNotSelected: true
                };
              }
            } else {
              incorrectLines[documentItem.name].errorAddresses = {
                [position.address]: {
                  addressNotSelected: true
                }
              };
            }
          } else {
            incorrectLines[documentItem.name] = {
              errorAddresses: {
                [position.address]: {
                  addressNotSelected: true
                }
              }
            };
          }
        }
      });

      // Если есть адрес, то проверить, в списке существующих ли он (addressNotInStorage)
      const displayedAddresses = addresses.filter((address) => !existingItemAddresses.includes(address.value));
      if (displayedAddresses.length) {
        documentItem.userPositions.forEach((position) => {
          if (position.address_after) {
            const addressInStorage = displayedAddresses.map((address) => address.name).includes(position.address_after);
  
            if (!addressInStorage) {
              if (incorrectLines[documentItem.name]) {
                if (incorrectLines[documentItem.name].errorAddresses) {
                  if (incorrectLines[documentItem.name].errorAddresses[position.address]) {
                    incorrectLines[documentItem.name].errorAddresses[position.address].addressNotInStorage = true;
                  } else {
                    incorrectLines[documentItem.name].errorAddresses[position.address] = {
                      addressNotInStorage: true
                    };
                  }
                } else {
                  incorrectLines[documentItem.name].errorAddresses = {
                    [position.address]: {
                      addressNotInStorage: true
                    }
                  };
                }
              } else {
                incorrectLines[documentItem.name] = {
                  errorAddresses: {
                    [position.address]: {
                      addressNotInStorage: true
                    }
                  }
                };
              }
            }
          }
        });
      } else {
        if (documentItemIndex === 0) {
          toast.error('Список адресов ещё не успел загрузиться', {
            position: 'bottom-right'
          });
        }
      }

      // Если в строке (в товаре) есть ошибка хоть в одной из подстрок,
      // то добавляем данные о товаре к соответствующему объекту в incorrectLines
      if (incorrectLines[documentItem.name]) {
        incorrectLines[documentItem.name].good = documentItem;
        incorrectLines[documentItem.name].lineNumber = documentItemIndex + 1;
      }
    });

    if (Object.keys(incorrectLines).length) {
      setCheckShiftingValuesModalData(incorrectLines);
      setIsCheckShiftingValuesModalActive(true);
      return false
    } else {
      return true;
    }
  };

  // Создание нового документа
  const createNewDocument = () => {
    // Выполнить проверку заполнения номера документа и имени бригадира
    const isDocumentNumberInserted = checkDocumentNumberInserted();
    const isEmployeeInserted = checkEmployeeInserted();
    const isStorageSelected = checkStorageSelected();

    const documentType = (isInCharges && DocumentTypeNameMap[DocumentType.CHARGE]) || (isInPostings && DocumentTypeNameMap[DocumentType.POSTING]) || (isInShiftings && DocumentTypeNameMap[DocumentType.SHIFTING]);
    const documentsListLocation = (isInCharges && AppRoute.Document.CHARGES) || (isInPostings && AppRoute.Document.POSTINGS) || (isInShiftings && AppRoute.Document.SHIFTINGS);

    if (isDocumentNumberInserted && isEmployeeInserted && isStorageSelected) {
      dispatch(postDocumentAsyncAction({
        type: documentType,
        form: documentItems,
        document: {
          number: documentNumber,
          date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
        },
        storage,
        employee,
        comment
      }))
      // отправить файлы на сервер, если они есть
      .then((res) => {
        const createdDocumentId = res.payload.id;

        if (commentFiles.length) {
          sendCommentFiles(createdDocumentId, commentFiles);
        }

        // Очистить стейт и localStorage от данных нового документа после его отправки на сервер
        dispatch(resetDocumentAction());
        if (isInCharges) {
          localStorage.removeItem('newChargeDocument');
        }
        if (isInPostings) {
          localStorage.removeItem('newPostingDocument');
        }
        if (isInShiftings) {
          localStorage.removeItem('newShiftingDocument');
        }

        // Перейти в новый документ
        dispatch(redirectToRoute(`${documentsListLocation}/${createdDocumentId}`));
      });
    }
  };

  // Обновление (сохранение) нового документа
  const updateDocument = async () => {
    // Выполнить проверку заполнения номера документа и имени бригадира
    const isDocumentNumberInserted = checkDocumentNumberInserted();
    const isEmployeeInserted = checkEmployeeInserted();
    const isStorageSelected = checkStorageSelected();

    const documentType = (isInCharges && DocumentTypeNameMap[DocumentType.CHARGE]) || (isInPostings && DocumentTypeNameMap[DocumentType.POSTING]) || (isInShiftings && DocumentTypeNameMap[DocumentType.SHIFTING]);

    if (isDocumentNumberInserted && isEmployeeInserted && isStorageSelected) {
      setIsPreloaderActive(true);

      await dispatch(updateDocumentAsyncAction({
        id,
        body: {
          type: documentType,
          form: documentItems,
          document: {
            number: documentNumber,
            date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
          },
          storage,
          employee,
          comment
        }
      }))
      .then(() => {
        dispatch(fetchDocumentAsyncAction(id));
      });

      setIsPreloaderActive(false);
    }
  };

  // Копирование документа
  const copyDocument = async () => {
    // Выполнить проверку заполнения номера документа и имени бригадира
    const isDocumentNumberInserted = checkDocumentNumberInserted();
    const isEmployeeInserted = checkEmployeeInserted();
    const isStorageSelected = checkStorageSelected();

    const documentType = (isInCharges && DocumentTypeNameMap[DocumentType.CHARGE]) || (isInPostings && DocumentTypeNameMap[DocumentType.POSTING]) || (isInShiftings && DocumentTypeNameMap[DocumentType.SHIFTING]);
    const documentsListLocation = (isInCharges && AppRoute.Document.CHARGES) || (isInPostings && AppRoute.Document.POSTINGS) || (isInShiftings && AppRoute.Document.SHIFTINGS);

    // Вычислить номер копии
    const isCurrentDocNumberDigital = (/^\d.+$/).test(documentNumber);
    const copyNumbers = [0, ...chargeDocumentsNumbers
      .filter((listDocNumber) => isCurrentDocNumberDigital
        ? listDocNumber.startsWith(documentNumber)
        : listDocNumber.includes(documentNumber))
      .map((docNumber) => docNumber.split(' ')[2] ? Number(docNumber.split(' ')[2]) : 0)]
      .sort((a, b) => a - b);
    const copyNumber = copyNumbers.find((docNumber) => !copyNumbers.includes(docNumber + 1)) + 1;

    const copyDocumentNumber = `${documentNumber.split(' ')[0]} копия ${copyNumber}`;
    const copyDocumentDate = generateDate();

    if (isDocumentNumberInserted && isEmployeeInserted && isStorageSelected) {
      dispatch(postDocumentAsyncAction({
        type: documentType,
        form: documentItems,
        document: {
          number: copyDocumentNumber,
          date: copyDocumentDate
        },
        storage,
        employee,
        comment
      }))
      // отправить файлы на сервер, если они есть
      .then((res) => {
        // Скопировать файлы
        const createdDocumentId = res.payload.id;
        dispatch(copyFormFilesAsyncAction({
          sourceId: id,
          targetId: createdDocumentId
        }));

        // Очистить стейт и localStorage от данных нового документа после его отправки на сервер
        dispatch(resetDocumentAction());
        if (isInCharges) {
          localStorage.removeItem('newChargeDocument');
        }
        if (isInPostings) {
          localStorage.removeItem('newPostingDocument');
        }

        // Перейти в соответствующий список документов (списания или оприходования)
        dispatch(redirectToRoute(`${documentsListLocation}`));
      });
    }
  };

  // Обработчик кнопки сохранения документа
  const handleSaveButtonClick = () => {
    alert('Сохранить документ?', 'default', 0, [
      {
        text: 'Да',
        handler: id ? updateDocument : createNewDocument,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки удаления документа
  const handleDeleteDocumentButtonClick = () => {
    const documentsListLocation = (isInCharges && AppRoute.Document.CHARGES) || (isInPostings && AppRoute.Document.POSTINGS) || (isInShiftings && AppRoute.Document.SHIFTINGS);

    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(deleteDocumentAsyncAction(id));
      dispatch(redirectToRoute(documentsListLocation));
      setIsPreloaderActive(false);
    };

    alert('Удалить документ?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки проведения в Системе
  const handleProvideDocumentButtonClick = () => {
    const handler = async () => {
      const linesFilledCorrectly = isInShiftings ? validateShiftingItems() : checkLinesFilledCorrectly();

      if (linesFilledCorrectly) {
        setIsPreloaderActive(true);
        await updateDocument();
        await dispatch(provideDocumentAsyncAction(id));
        dispatch(fetchDocumentAsyncAction(id));
        setIsPreloaderActive(false);
      }
    };

    alert('Провести в системе?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик отмены проведения документа списания
  const handleDenyDocumentProvidingButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(denyProvidingDocumentAsyncAction(id));
      dispatch(fetchDocumentAsyncAction(id));
      setIsPreloaderActive(false);
    };

    alert('Отменить проведение в Системе?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки проведения в Бизнес.ру
  const handleBruProvideButtonClick = () => {
    const documentsListLocation = (isInCharges && AppRoute.Document.CHARGES) || (isInPostings && AppRoute.Document.POSTINGS) || (isInShiftings && AppRoute.Document.SHIFTINGS);

    const handler = async () => {
      setIsPreloaderActive(true);

      const result = await dispatch(provideInBruAsyncAction(id));

      if (!result.payload.form.unavailableForChargeGoods) {
        dispatch(redirectToRoute(documentsListLocation));
      }

      setIsPreloaderActive(false);
    };

    alert('Провести в Бизнес.ру?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки записи коррекции
  const handleMakeCorrectionButtonClick = () => {
    const documentsListLocation = (isInCharges && AppRoute.Document.CHARGES) || (isInPostings && AppRoute.Document.POSTINGS) || (isInShiftings && AppRoute.Document.SHIFTINGS);

    const handler = async (withHistory) => {
      setIsPreloaderActive(true);
      await dispatch(createDocumentCorrectionAsyncAction({
        id,
        withHistory
      }));
      dispatch(redirectToRoute(documentsListLocation));
      setIsPreloaderActive(false);
    };

    alert('Сделать коррекцией?', 'default', 0, [
      {
        // text: 'С историей',
        text: 'Да',
        handler: () => handler(true),
        needToCloseImmediately: true
      },
      // {
      //   text: 'Без Истории',
      //   handler
      // },
      {
        text: 'Нет',
        handler: function () {
          alert('', 'default', 1);
        }
      }
    ])
  };

  // Обработчик кнопки обновления остатков товаров в документе
  // (временно отключён, т.к. отключена кнопка)
  // const handleRefreshRemainsButtonClick = async () => {
  //   await dispatch(refreshDocumentAmountsAsyncAction(id));
  //   dispatch(fetchChargeDocumentAsyncAction(id));
  // };

  const handleCopyDocumentButtonClick = () => {
    alert('Копировать документ?', 'default', 0, [
      {
        text: 'Да',
        handler: copyDocument,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  const tmpButtonHandler = () => alert('Кнопка временно не работает', 'success', 3000);

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      {/* Кнопка удаления документа */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDeleteDocumentButtonClick}
            title="Удалить"
          >
            delete_sweep
          </span>
        )
      }

      {/* Кнопка проведения документа в системе */}
      {
        canUserUpdate && provided && !done && (
          <>
            {/* Кнопка отмены проведения */}
            <span
              className="roundIcon material-icons dangerIcon"
              onClick={handleDenyDocumentProvidingButtonClick}
              title="Отменить проведение"
            >
              undo
            </span>
            
            {/* Кнопка проведения в Бизнес.ру */}
            {/* form.provided === 1 */}
            <span
              className="roundIcon material-icons successIcon"
              onClick={handleBruProvideButtonClick}
              title="Провести в Базе"
            >
              done
            </span>
          </>
        )
      }
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleProvideDocumentButtonClick}
            title="Провести в Системе"
          >
            checklist
          </span>
        )
      }

      {/* Кнопка обновления остатков */}
      {
        // id && canUserUpdate && !provided && (
        //   <span
        //     className="roundIcon material-icons"
        //     onClick={handleRefreshRemainsButtonClick}
        //     title="Обновить остатки"
        //   >
        //     refresh
        //   </span>
        // )
      }

      {/* Кнопка копирования документа */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleCopyDocumentButtonClick}
            title="Скопировать документ"
          >
            copy
          </span>
        )
      }

      {/* Кнопка записи коррекции документа */}
      {
        canUserUpdate && provided && !isInArchive && (
          <span
            className="roundIcon material-icons"
            onClick={handleMakeCorrectionButtonClick}
            title="Записать коррекцию"
          >
            inventory
          </span>
        )
      }

      {/* Кнопка сохранения документа */}
      {
        canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить документ"
          >
            save
          </span>
        )
      }

      {/* Кнопка скачивания документа */}
      {
        id && (
          <span
            className="roundIcon material-icons"
            onClick={tmpButtonHandler}
            title="Скачать"
          >
            download
          </span>
        )
      }

      {/* Кнопка открытия модального окна примечания */}
      <span
        className="roundIcon material-icons"
        onClick={() => setModalActive(true)}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={tmpButtonHandler}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default DocumentsFloatPanel;
