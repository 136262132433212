import {useContext, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';

import {fetchSupplies} from 'redux/slices/business/businessSlice';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

const Index = () => {
	const navigate = useNavigate();
	const {search} = useLocation();
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

	const dispatch = useDispatch();
	const {supplies} = useSelector((state) => state.business);
	
	const {setCrumbs, setMenuCondition} = useContext(AppContext);
	
	useEffect(() => {
		// Сброс условия меню
		setMenuCondition({});
	}, [search]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: DocumentTypeNameMap[DocumentType.SUPPLY], url: ''}
		]);
	}, []);

	useEffect(() => {
		dispatch(fetchSupplies());
	}, []);

	return (
		<>
			{!supplies.data.length && <p>&nbsp; Новых поступлений нет</p>}

			{
        !isInArchive && (
          <DocumentsListButtonsBlock
						documentType={DocumentType.SUPPLY}
						withoutNewButton={true}
					/>
        )
      }
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>№</th>
						<th>Тип</th>
						<th>Дата</th>
						<th>Склад</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
						supplies.data.map((form, index) =>
							<tr key={index.toString()}>
								<td>
									<span>{form.document_number}</span>
								</td>
								<td>
									<span>Поступление</span>
								</td>
								<td>
									<span>{form.document_date.slice(0, -6)}</span>
								</td>
								<td>
									<span>{form.storage ? form.storage : 'НЕ ВЫБРАНО'}</span>
								</td>
								<td>
									<span
										className={cl.icon + ' material-icons'}
										title="Посмотреть"
										onClick={() => navigate(`${AppRoute.Document.SUPPLIES}/${form.supply_id}`)}>
										visibility
									</span>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</>
	);
}

export default Index;
