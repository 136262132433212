import {createAsyncThunk} from '@reduxjs/toolkit';

import {Good, Synchronization} from 'constants/endpoints';

export const syncGoodsAsyncAction = createAsyncThunk(
  'good/syncGoods',
  async (_args, {extra: api}) => {
    await api.get(Synchronization.SYNC_VIA_NODE_APP);
  }
);

export const fetchGoodsAsyncAction = createAsyncThunk(
  'good/fetchGoods',
  async (_args, {extra: api}) => {
    const {data} = await api.get(Good.ALL);
    return data;
  }
);
