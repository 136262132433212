import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import Modal from 'components/Modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import RealizationCheckValuesModal from 'components/realization-check-values-modal/realization-check-values-modal';
import RealizationTable from 'components/realization-table/realization-table';
import RealizationsFloatPanel from 'components/realizations-float-panel/realizations-float-panel';

import {
	fetchRealizationAsyncAction,
  removeCommentFileAsyncAction,
  saveRealizationAsyncAction,
  setCommentFilesAsyncAction
} from 'redux/slices/realizations/realizations-api-actions';
import {
	getRealizationComment,
	getRealizationCommentFiles,
	getRealizationDocumentDate,
	getRealizationDocumentNumber,
	getRealizationDoneStatus,
	getRealizationEmployee,
	getRealizationHead,
	getRealizationItems,
	getRealizationProvidedStatus,
  getRealizationsFileName
} from 'redux/slices/realizations/selectors';
import {fetchGroups} from 'redux/slices/business/businessSlice';
import {fetchAddresses} from 'redux/slices/inventory/inventorySlice';
import {
	resetState,
	setRealizationCommentAction
} from 'redux/slices/realizations/realizations';
import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';

import {AppContext} from 'providers/AppContextProvider';
import {restrictedGroupsIds} from 'constants/document-data';

import styles from './styles.module.scss';
import {AppRoute} from 'constants/routes';

const RealizationDetails = () => {
	const {id} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};

	const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

	const {alert, setCrumbs} = useContext(AppContext);

	const addressList = useSelector((state) => state.inventory).addresses.data;
	const groups = useSelector((state) => state.business).groups.data
    .filter((group) => !restrictedGroupsIds.includes(Number(group.id)));

	const toScreenShot = useRef();
	const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  const head = useSelector(getRealizationHead);
	const realizationItems = useSelector(getRealizationItems);
	const commentFiles = useSelector(getRealizationCommentFiles);
	const provided = useSelector(getRealizationProvidedStatus);
	const done = useSelector(getRealizationDoneStatus);
	const documentNumber = useSelector(getRealizationDocumentNumber);
	const documentDate = useSelector(getRealizationDocumentDate);
	const comment = useSelector(getRealizationComment);
  const filename = useSelector(getRealizationsFileName);
	const employee = useSelector(getRealizationEmployee);

	// Модальное окно примечания
	const [modalIsActive, setModalIsActive] = useState(false);

	// Модальное окно со списком некорректно заполненных строк
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);
  const [isCheckValuesModalActive, setIsCheckValuesModalActive] = useState(false);

	// Модальное окно товаров, которые невозможно списать при втором проведении
  const [unavailableGoodsModalActive, setUnavailableGoodsModalActive] = useState(false);
	const [unavailableForShiftingGoods, setUnavailableForShiftingGoods] = useState([]);

	// Запись хлебных крошек
	useEffect(() => {
		if (realizationItems.length && documentNumber) {
			const crumbs = isInArchive ? [
				{name: "Архивы", url: "/archive"},
				{name: "Отгрузки", url: "/realizations"},
				{name: "№ " + documentNumber + " (редактирование)", url: ""}
			] : [
				{name: "Отгрузки", url: "/realizations"},
				{name: "№ " + documentNumber + " (редактирование)", url: ""}
			]
			setCrumbs(crumbs);
		}
	}, [realizationItems.length]);

  const saveRealization = async () => {
    const response = await dispatch(saveRealizationAsyncAction({
      id,
      body: {
        realization: realizationItems,
        head: {
          number: head.number,
          customer: head.customer
        },
        documentNumber,
        documentDate,
        filename,
        provided,
				employee,
        comment
      }
    }));

    return response;
  };

	// Обработчик кнопки копирования номеров строк в модальном окне списка товаров,
  // которые невозможно отгрузить из-за недостаточного кол-ва в Бизнес.ру
  const handleCopyUnavailableGoodsLinesNumbers = () => {
    const linesNumbers = unavailableForShiftingGoods.map((good) => {
      const lineNumber = realizationItems.findIndex((item) => String(item.good_id) === String(good.good_id)) + 1;
      return lineNumber;
    });
    navigator.clipboard.writeText(linesNumbers.join(' '));
  };

	// Обработчик кнопки закрытия модального окна со списком товаров,
  // которые невозможно отгрузить из-за недостаточного кол-ва в Бизнес.ру
  const handleUnavailableGoodsModalCloseButtonClick = () => {
    setUnavailableForShiftingGoods([]);
    setUnavailableGoodsModalActive(false);
  };

  const handleFileInputChange = async (evt) => {
    // Сборка FormData
		const formData = new FormData();
		Object.values(evt.target.files).forEach((file, index) => {
			formData.append('file_' + index, file);
		});

    const response = await dispatch(setCommentFilesAsyncAction({
      id,
      body: formData
    }));

    if (response.error) {
      toast.error('Ошибка добавления файла', {
        position: 'bottom-right'
      });
    } else {
      await dispatch(fetchRealizationAsyncAction(id));
      toast.success(response.data.files.length > 1 ? 'Файлы загружены' : 'Файл загружен', {
        position: 'bottom-right'
      });
    }
  };

  const handleRemoveCommentFileButtonClick = (url) => {
    const handler = async () => {
      const response = await dispatch(removeCommentFileAsyncAction({
        filename: url
      }));

      if (response.error) {
        toast.error('Ошибка удаления файла', {
          position: 'bottom-right'
        });
      } else {
        await dispatch(fetchRealizationAsyncAction(id));
        toast.success('Файл удален', {
          position: 'bottom-right'
        });
      }
    };

    alert('Удалить файл?', 'danger', 0, [
			{
				text: 'Да',
				handler,
				needToCloseImmediately: true
			},
			{
				text: 'Нет',
				handler: () => {
					alert('', 'default', 1);
				}
			}
		]);
  };

	const requestForDocumentDetails = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchRealizationAsyncAction(id));
		setIsPreloaderActive(false);
	};

  const handleCommentModalCloseButtonClick = () => {
    saveRealization();
    setModalIsActive(false);
    dispatch(fetchRealizationAsyncAction(id));
  };

  const handleTextAreaChange = (evt) => {
    dispatch(setRealizationCommentAction(evt.currentTarget.value));
  };

	useEffect(() => {
		requestForDocumentDetails();
	}, [id]);

  useEffect(() => {
    dispatch(fetchGoodsAsyncAction());
  }, []);

	useEffect(() => {
		if (!groups.length) {
      dispatch(fetchGroups());
    }
		if (!addressList.length) {
			dispatch(fetchAddresses());
		}
	}, []);

	useEffect(() => {
		if (done === 1 && !isInArchive) {
			navigate(`${AppRoute.Archive.REALIZATIONS}/${id}`);
		}
		if (done === 0 && isInArchive) {
			navigate(`${AppRoute.Document.REALIZATIONS}/edit/${id}`);
		}
	}, [done, isInArchive]);

	// Очистка стейта после отмонтирования компонента
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

	return (
		<>
			<RealizationsFloatPanel
				setModalIsActive={setModalIsActive}
				setIsPreloaderActive={setIsPreloaderActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setIsCheckValuesModalActive={setIsCheckValuesModalActive}
        setUnavailableGoodsModalActive={setUnavailableGoodsModalActive}
        setUnavailableForShiftingGoods={setUnavailableForShiftingGoods}
        saveRealization={saveRealization}
			/>
			
			<div className="stickyContainer" ref={toScreenShot}>
				<RealizationTable/>
			</div>
			
			<Modal isActive={modalIsActive} setIsActive={handleCommentModalCloseButtonClick}>
				{
					realizationItems.length && done === 0
						? (
							<>
								<textarea placeholder="Примечание" onChange={handleTextAreaChange}>
									{comment}
								</textarea>
								<input type="file" name="files[]" onChange={handleFileInputChange} multiple/>
							</>
						)
						: comment
				}
				{
					commentFiles.length && commentFiles.map((file) =>
						<div className="fileIconGroup">
							<span
								className="material-icons remove"
								title="Удалить"
								onClick={handleRemoveCommentFileButtonClick}
							>
								cancel
							</span>
							<span
								className="material-icons file"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}
							>
								description
							</span>
							<span
								className="name"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}
							>
								{file.name}
							</span>
						</div>
					)
				}
			</Modal>

			{
				unavailableForShiftingGoods.length && (
					<Modal isActive={unavailableGoodsModalActive} setIsActive={handleUnavailableGoodsModalCloseButtonClick}>
						{
							unavailableForShiftingGoods.map((good) => (
								<div>
									<h5 className={styles.title}>
										{'Невозможно выполнить отгрузку указанного товара из-за недостаточного кол-ва в Бизнес.ру: '}
										<span
											className="roundIcon material-icons"
											onClick={handleCopyUnavailableGoodsLinesNumbers}
											title="Скопировать строки"
										>
											copy
										</span>
									</h5>
									<table className={styles.table}>
										<tr className={styles.row}>
											<td className={styles.cell}>Номер строки</td>
											<td className={styles.cell}>Наименование</td>
											<td className={styles.cell}>Склад</td>
											<td className={styles.cell}>Доступное кол-во для отгрузки</td>
										</tr>
										<tr>
											<td className={styles.cell}>
												{realizationItems.findIndex((item) => String(item.good_id) === String(good.good_id)) + 1}
											</td>
											<td className={styles.cell}>
												<span>{good.name}</span>
											</td>
											<td className={styles.cell}>
												<span>{good.storage}</span>
											</td>
											<td className={styles.cell}>
												<span>{good.total_storage_amount}</span>
											</td>
										</tr>
									</table>
								</div>
							))
						}
					</Modal>
				)
			}

			<RealizationCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setIsCheckValuesModalActive}
      />

			<Preloader isActive={isPreloaderActive}/>
		</>
	);
};

export default RealizationDetails;
