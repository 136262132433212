import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
import ListLegend from 'components/list-legend/list-legend';
import FactoriesListItem from 'components/factories-list-item/factories-list-item';
import InputDate from 'components/input/InputDate';

import {
  fetchFactoriesAsyncAction,
  fetchFactoryDocumentsNumbersAsyncAction
} from 'redux/slices/factory/factory-api-actions';
import {getFactories} from 'redux/slices/factory/selectors';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';

import {getUser} from 'helpers/storage';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';

import cl from 'styles/components/cabinet/UsersTable.module.scss';

const FactoriesList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {setCrumbs} = useContext(AppContext);

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  const userLevel = getUser().accessLevel;

  const factories = useSelector(getFactories);

  const sortedByDateDocuments = structuredClone(factories)
    .sort((currentDocument, nextDocument) => {
      const currentItemDate = new Date(
        ...currentDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );
      const nextItemDate = new Date(
        ...nextDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );

      const currentItemNumber = (/\d+/).test(currentDocument.document_number) ? Number(currentDocument.document_number.match(/\d+/)[0]) : 0;
		  const nextItemNumber = (/\d+/).test(nextDocument.document_number) ? Number(nextDocument.document_number.match(/\d+/)[0]) : 0;

      const currentItemCopyNumber = currentDocument.document_number.split(' ')[2] ? Number(currentDocument.document_number.split(' ')[2]) : 0;
      const nextItemCopyNumber = nextDocument.document_number.split(' ')[2] ? Number(nextDocument.document_number.split(' ')[2]) : 0;

      if (nextItemDate.getTime() === currentItemDate.getTime()) {
        if (nextItemNumber === currentItemNumber) {
          return nextItemCopyNumber - currentItemCopyNumber;
        } else {
          return nextItemNumber - currentItemNumber;
        }
      } else {
        return nextItemDate - currentItemDate;
      }
    });

  // Запрос списка производств
  useEffect(() => {
    if (isInArchive) {
      dispatch(fetchFactoriesAsyncAction(true));
    } else {
      dispatch(fetchFactoriesAsyncAction());
      dispatch(fetchFactoryDocumentsNumbersAsyncAction());
    }
  }, [isInArchive]);

  // Запись хлебных крошек
  useEffect(() => {
    const crumbs = isInArchive
      ? [
          {name: 'Архивы', url: AppRoute.Archive.ALL},
          {name: 'Производство', url: ''}
        ]
      : [
          {name: 'Производство', url: ''}
        ];

    setCrumbs(crumbs);
  }, [isInArchive]);

  return (
    <>
      {
        !isInArchive && (
          <ListLegend documentType={DocumentTypeNameMap[DocumentType.FACTORY]}/>
        )
      }

      {
        !isInArchive && userLevel > 1 && (
          <DocumentsListButtonsBlock documentType={DocumentType.FACTORY}/>
        )
      }

			<table className={cl.usersTable}>
				<thead>
          {
            isInArchive && (
              <tr>
                <th colSpan="2">
                  <div style={{display: "inline-block", width: "45%"}}>
                    <InputDate
                      name="after"
                      // currentValue={period.after}
                      // changeHandler={changePeriod}
                    />
                  </div>
                  <div style={{display: "inline-block", width: "4%"}}>—</div>
                  <div style={{display: "inline-block", width: "45%"}}>
                    <InputDate
                      name="before"
                      // currentValue={period.before}
                      // changeHandler={changePeriod}
                    />
                  </div>
                </th>
                <th colSpan={userLevel === 3 ? "3" : "2"}>
                  <input
                    type="search"
                    name="commentSearch"
                    // value={search}
                    // title={search}
                    placeholder="Поиск по примечанию"
                    // onChange={(e) => setSearch(e.target.value)}
                  />
                </th>
              </tr>
            )
          }
					<tr>
						<th>№</th>
						<th>Дата в Системе</th>
						<th>Дата производства</th>
						<th>Наименование</th>
						<th>Склад</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
            sortedByDateDocuments.map((factory, index) => (
              <FactoriesListItem
                key={index}
                factory={factory}
              />
            ))
          }
				</tbody>
			</table>
		</>
  );
};

export default FactoriesList;
