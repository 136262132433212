import {useContext} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
  deleteRealizationAsyncAction,
  denyRealizationAsyncAction,
  fetchRealizationAsyncAction,
  fetchRealizationsListAsyncAction,
  provideInBusinessRuAsyncAction,
  provideRealizationAsyncAction
} from 'redux/slices/realizations/realizations-api-actions';
import {
  getRealizationDocumentNumber,
  getRealizationDoneStatus,
  getRealizationEmployee,
  getRealizationItems,
  getRealizationProvidedStatus
} from 'redux/slices/realizations/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';
import {toast} from 'react-toastify';

const RealizationsFloatPanel = ({
  setModalIsActive,
  setIsPreloaderActive,
  setCheckValuesModalData,
  setIsCheckValuesModalActive,
  setUnavailableGoodsModalActive,
  setUnavailableForShiftingGoods,
  saveRealization
}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {alert} = useContext(AppContext);

  // Уровень прав пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const realizationItems = useSelector(getRealizationItems);
  const provided = useSelector(getRealizationProvidedStatus);
  const done = useSelector(getRealizationDoneStatus);
  const documentNumber = useSelector(getRealizationDocumentNumber);
  const employee = useSelector(getRealizationEmployee);

  // Проверка ввода номера документа
  const checkDocumentNumberInserted = () => {
    if (!documentNumber) {
      toast.error('Укажите номер документа!', {
        position: 'bottom-right'
      });
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода имени бригадира
  const checkEmployeeInserted = () => {
    if (!employee) {
      toast.error('Укажите бригадира!', {
        position: 'bottom-right'
      });
      return false;
    } else {
      return true;
    }
  };

	// Проверка правильности заполнения строк
  const checkLinesFilledCorrectly = () => {
    const incorrectlyFilledLines = {};

    realizationItems.forEach((line, lineIndex) => {
      const lineNumber = lineIndex + 1;
      const userPositions = line.userPositions;
      const existingAmounts = line.amounts;

      const allSubLinesEmpty = userPositions.every((userPosition) => !userPosition.amount && !userPosition.address);

			// Проверка на равенство суммы введённых значений по адресам общему кол-ву
      const totalUserPositionAmount = userPositions.reduce((res, position) => res + Number(position.amount), 0);
      const isAmountsEqualsToTotal = totalUserPositionAmount === line.orderAmount;

			if (!isAmountsEqualsToTotal) {
        if (incorrectlyFilledLines[lineNumber]) {
          incorrectlyFilledLines[lineNumber].amountNotEqualsToTotal = true;
        } else {
          incorrectlyFilledLines[lineNumber] = {
            amountNotEqualsToTotal: true
          };
        }
      }

      // Валидация каждой подстроки (адрес и кол-во)
      userPositions.forEach((userPosition) => {
				const userPositionAmount = Number(userPosition.amount);
				const userPositionAddress = userPosition.address;
				const existingAmountsItem = existingAmounts.find((amountItem) => amountItem.address === userPositionAddress);

        // Условия валидации значений
				const isSelectedAddressHasSelectedGood = !!existingAmountsItem;
				const isAmountWithinExistingValue = existingAmountsItem && userPositionAmount <= Number(existingAmountsItem.amount);

        // Следует ли показывать соответствующую ошибку
				const shouldSelectedAddressDoesNotHaveGoodErrorBeShown = !isSelectedAddressHasSelectedGood;
				const shouldAmountExceedsExistingValueErrorBeShown = isSelectedAddressHasSelectedGood && !isAmountWithinExistingValue;

				if (shouldSelectedAddressDoesNotHaveGoodErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].addressDoesNotHaveGood = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              addressDoesNotHaveGood: true
            };
          }
        }
				if (shouldAmountExceedsExistingValueErrorBeShown) {
          if (incorrectlyFilledLines[lineNumber]) {
            incorrectlyFilledLines[lineNumber].amountExceedsExistingValue = true;
          } else {
            incorrectlyFilledLines[lineNumber] = {
              amountExceedsExistingValue: true
            };
          }
        }
      });

      // Хотя бы для одного адреса должно быть указано кол-во прихода и адрес
      if (allSubLinesEmpty) {
        incorrectlyFilledLines[lineNumber] = {
          ...incorrectlyFilledLines[lineNumber],
          allSubLinesEmpty: true
        };
      }

      // Если в строке (в товаре) есть ошибка хоть в одной из подстрок,
      // то добавляем данные о товаре к объекту incorrectlyFilledLines
      if (incorrectlyFilledLines[lineNumber]) {
        incorrectlyFilledLines[lineNumber] = {
          ...incorrectlyFilledLines[lineNumber],
          line
        };
      }
    });

    if (Object.keys(incorrectlyFilledLines).length) {
      setCheckValuesModalData(incorrectlyFilledLines);
      setIsCheckValuesModalActive(true);
      return false
    } else {
      return true;
    }
  };

  const handleSaveButtonClick = () => {
    const handler = async () => {
      const documentNumberInserted = checkDocumentNumberInserted();

      if (documentNumberInserted) {
        setIsPreloaderActive(true);
        const response = await saveRealization();

        if (response.error) {
          toast.error('Ошибка сохранения', {
            position: 'bottom-right'
          });
        } else {
          toast.success('Успешно сохранено', {
            position: 'bottom-right'
          });
        }

        setIsPreloaderActive(false);
      }
    };

    alert('Сохранить?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        }
      }
    ])
  };

  const handleDeleteButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      const response = await dispatch(deleteRealizationAsyncAction(id));

      if (response.error) {
        toast.error('Ошибка удаления', {
          position: 'bottom-right'
        });
      } else {
        await dispatch(fetchRealizationsListAsyncAction());
        navigate(AppRoute.Document.REALIZATIONS);
        toast.success('Успешно удалено', {
          position: 'bottom-right'
        });
      }

      setIsPreloaderActive(false);
    };

    alert('Удалить?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        }
      }
    ])
  };

  const handleDownloadButtonClick = () => {};

  const handleProvideButtonClick = () => {
    const handler = async () => {
      const documentNumberInserted = checkDocumentNumberInserted();
		  const employeeInserted = checkEmployeeInserted();
		  const linesFilledCorrectly = checkLinesFilledCorrectly();

      if (documentNumberInserted && employeeInserted && linesFilledCorrectly) {
        setIsPreloaderActive(true);

        const saveResponse = await saveRealization();
        if (saveResponse.error) {
          toast.error('Ошибка сохранения', {
            position: 'bottom-right'
          });
          return;
        }

        const provideResponse = await dispatch(provideRealizationAsyncAction(id));
        if (provideResponse.error) {
          toast.error('Ошибка проведения', {
            position: 'bottom-right'
          });
        } else {
          await dispatch(fetchRealizationAsyncAction(id));
          toast.success('Успешно проведено', {
            position: 'bottom-right'
          });
        }

        setIsPreloaderActive(false);
      }
    };

    alert('Провести в Системе?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        }
      }
    ])
  };

  const handleDenyButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      const response = await dispatch(denyRealizationAsyncAction({
        id,
        body: {
          deny: 1
        }
      }));

      if (response.error) {
        toast.error('Ошибка отмены проведения', {
          position: 'bottom-right'
        });
      } else {
        await dispatch(fetchRealizationAsyncAction(id));
        toast.success('Успешно отменено', {
          position: 'bottom-right'
        });
      }

      setIsPreloaderActive(false);
    };

    alert('Отменить отгрузку?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        }
      }
    ])
  };

  const handleProvideInBusinessRuButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      const response = await dispatch(provideInBusinessRuAsyncAction(id));

      if (response.payload.unavailableForShiftingGoods) {
        setUnavailableForShiftingGoods(response.payload.unavailableForShiftingGoods);
        setUnavailableGoodsModalActive(true);
        setIsPreloaderActive(false);
        return;
      }

      if (response.error) {
        toast.error('Ошибка проведения', {
          position: 'bottom-right'
        });
      } else {
        navigate(AppRoute.Document.REALIZATIONS);
        toast.success('Готово. Не забудьте оформить эту отгрузку в базе!', {
          position: 'bottom-right'
        });
      }

      setIsPreloaderActive(false);
    };

    alert('Провести в Базе?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => {
          alert('', 'default', 1);
        }
      }
    ])
  };

  return (
    <div className={`${styles.floatPanel} ${styles.controlsPanel}`}>
      {/* Кнопка удаления документа отгрузки */}
      {
        realizationItems.length && provided === 0 && canUserUpdate && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDeleteButtonClick}
            title="Удалить отгрузку"
          >
            delete_sweep
          </span>
        )
      }

      {/* Кнопка скачивания документа */}
      {
        realizationItems.length && (
          <span
            className="roundIcon material-icons"
            onClick={handleDownloadButtonClick}
            title="Скачать"
          >
            download
          </span>
        )
      }

      {/* Кнопка проведения */}
      {
        realizationItems.length && provided === 0 && canUserUpdate && (
          <span
            className="roundIcon material-icons"
            onClick={handleProvideButtonClick}
            title="Провести в Системе"
          >
            checklist
          </span>
        )
      }

      {/*  Кнопка отмены проведения */}
      {
        realizationItems.length && provided === 1 && done === 0 && canUserUpdate && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDenyButtonClick}
            title="Отменить"
          >
            undo
          </span>
        )
      }

      {/* Кнопка сохранения изменений */}
      {
        realizationItems.length && provided === 0 && canUserUpdate && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить"
          >
            save
          </span>
        )
      }

      {/* Кнопка проведения в Бизнес.ру */}
      {
        realizationItems.length && provided === 1 && done === 0 && canUserUpdate && (
          <span
            className="roundIcon material-icons"
            onClick={handleProvideInBusinessRuButtonClick}
            title="Провести в Базе"
          >
            done
          </span>
        )
      }

      {/* Кнопка открытия модального окна примечания */}
      <span
        className="roundIcon material-icons"
        onClick={() => setModalIsActive(true)}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в инструкцию */}
      <span
        className="roundIcon material-icons"
        onClick={() => window.open('/faq/realization', '_blank')}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default RealizationsFloatPanel;
