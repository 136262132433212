import clsx from 'clsx';

import Modal from 'components/Modal';

import style from './styles.module.scss';

  // type Data = {
  //   'Тестовый товар': {
  //     lineNumber: 2,
  //     good: {},
  //     errorAddresses: {
  //       '1В.1': {
  //         amountNotInserted: true,
  //         amountExceedsExistingValue: true,
  //         addressNotSelected: true,
  //         addressNotInStorage: true
  //       }
  //     },
  //     allSubLinesEmpty: true
  //   }
  // };

const CheckModalExperimental = ({
  data,           // typeof Data
  isActive,
  setIsActive
}) => {
  const handleCopyEmptyLinesButtonClick = () => {
    navigator.clipboard.writeText(Object.values(data).map((item) => item.lineNumber).join(' '));
  };

  const CopyEmptyRowsListButton = () => (
    <span
      className="roundIcon material-icons"
      onClick={handleCopyEmptyLinesButtonClick}
      title="Скопировать строки"
    >
      copy
    </span>
  );

  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        <h3 className={style.title}>
          {'Некорректно заполненные строки: '}
          <CopyEmptyRowsListButton />
        </h3>

        <table className={style.table}>
          <tr>
            <td className={style.cell}>Номер строки</td>
            <td className={style.cell}>Наименование</td>
            <td className={style.cell}>Площадка</td>
            <td className={style.cell}>Кол-во</td>
            <td className={style.cell}>Адрес назначения</td>
          </tr>

          {
            Object.keys(data).map((goodName) => {
              const itemNumber = data[goodName].lineNumber;
              const errorAddresses = data[goodName].errorAddresses ? Object.keys(data[goodName].errorAddresses) : [];

              if (data[goodName].allSubLinesEmpty) {
                return (
                  <tr>
                    <td
                      className={style.cell}
                    >
                      {`${itemNumber} `}
                    </td>
                    <td
                      className={style.cell}
                    >
                      {goodName}
                    </td>
                    <td colSpan={3} className={clsx(style.cell, style.error)}>
                      <span>Введите данные хотя бы для одного из адресов, либо удалите товар</span><br/>
                    </td>
                  </tr>
                );
              }

              return (
                <>
                  {
                    errorAddresses.map((errorAddress, errorAddressIndex) => (
                      <tr>
                        {
                          errorAddressIndex === 0 && (
                            <>
                              <td
                                rowSpan={errorAddresses.length}
                                className={style.cell}
                              >
                                {`${itemNumber} `}
                              </td>
                              <td
                                rowSpan={errorAddresses.length}
                                className={style.cell}
                              >
                                {goodName}
                              </td>
                            </>
                          )
                        }
                        <td className={style.cell}>{errorAddress}</td>
                        <td className={style.cell}>
                          {
                            (
                              data[goodName].errorAddresses[errorAddress].amountNotInserted ||
                              data[goodName].errorAddresses[errorAddress].amountExceedsExistingValue
                            ) ? (
                              <>
                                {
                                  data[goodName].errorAddresses[errorAddress].amountNotInserted && (
                                    <>
                                      <span>Не введено</span><br/>
                                    </>
                                  )
                                }
                                {
                                  data[goodName].errorAddresses[errorAddress].amountExceedsExistingValue && (
                                    <>
                                      <span>Превышает имеющееся</span><br/>
                                    </>
                                  )
                                }
                              </>
                            ) : <>✔️</>
                          }
                        </td>
                        <td className={style.cell}>
                          {
                            (
                              data[goodName].errorAddresses[errorAddress].addressNotSelected ||
                              data[goodName].errorAddresses[errorAddress].addressNotInStorage
                            ) ? (
                              <>
                                {
                                  data[goodName].errorAddresses[errorAddress].addressNotSelected && (
                                    <>
                                      <span>Не выбран</span><br/>
                                    </>
                                  )
                                }
                                {
                                  data[goodName].errorAddresses[errorAddress].addressNotInStorage && (
                                    <>
                                      <span>Не существует</span><br/>
                                    </>
                                  )
                                }
                              </>
                            ) : <>✔️</>
                          }
                        </td>
                      </tr>
                    ))
                  }
                </>
              );
            })
          }
          <tr></tr>
        </table>
      </div>
    </Modal>
  );
};

export default CheckModalExperimental;
