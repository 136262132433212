import {createSlice} from '@reduxjs/toolkit';

import {
  fetchDocumentAsyncAction,
  fetchDocumentsNumbersAsyncAction,
  fetchDocumentsAsyncAction,
  fetchCorrectionsAsyncAction,
  fetchGoodsByStorageAsyncAction,
  provideInBruAsyncAction,
  setNewDocumentInitialAmountsAsyncAction,
  fetchStorageAddressesAsyncAction,
  fetchGoodsByStorageFromHistoryAsyncAction
} from './documents-api-actions';

const initialState = {
  goods: [],
  author: '',
  commentFiles: [],
  provided: undefined,
  done: undefined,
  isCorrection: undefined,
  unavailableForChargeGoods: [],
  documentsNumbers: [],
  addresses: [],
  list: [],
  newDocumentDate: '',
  document: {
    documentNumber: '',
    documentDate: '',
    employee: '',
    storage: '',
    toStorage: '',
    comment: '',
    documentItems: []
  }
};

export const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // Используется для очистки стейта при отказе пользователя продолжить
    // заполнение начатого документа и после сохранения заполненного документа
    resetDocumentAction: (state) => {
      state.document = {
        documentNumber: '',
        documentDate: '',
        employee: '',
        storage: '',
        toStorage: '',
        comment: '',
        documentItems: []
      };
      state.author = '';
      state.provided = undefined;
      state.done = undefined;
      state.isCorrection = undefined;
      state.commentFiles = [];
      state.unavailableForChargeGoods = [];
      state.goods = [];
      state.newDocumentDate = '';
    },
    // Используется для заполнения стейта при согласии пользователя продолжить заполнение начатого документа
    setDocumentAction: (state, action) => {
      state.document = action.payload;
    },
    setDocumentDateAction: (state, action) => {
      state.document.documentDate = action.payload;
    },
    setNewDocumentDateAction: (state, action) => {
      state.newDocumentDate = action.payload;
    },
    setDocumentNumberAction: (state, action) => {
      state.document.documentNumber = action.payload;
    },
    setDocumentStorageAction: (state, action) => {
      state.document.storage = action.payload;
    },
    setDocumentDestinationStorageAction: (state, action) => {
      state.document.toStorage = action.payload;
    },
    setDocumentEmployeeAction: (state, action) => {
      state.document.employee = action.payload;
    },
    setDocumentCommentAction: (state, action) => {
      state.document.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.commentFiles = action.payload;
    },
    setDocumentItemsAction: (state, action) => {
      state.document.documentItems = action.payload;
    },
    setUnavailableForChargeGoodsAction: (state, action) => {
      state.unavailableForChargeGoods = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentsAsyncAction.fulfilled, (state, action) => {
        state.list = action.payload.result;
      })
      .addCase(fetchDocumentAsyncAction.fulfilled, (state, action) => {
        state.provided = action.payload.result.provided;
        state.done = action.payload.result.done;
        state.isCorrection = action.payload.result.is_correction;
        state.author = action.payload.result.author;
        state.document.documentNumber = action.payload.result.document_number;
        state.document.documentDate = action.payload.result.document_date;
        state.document.employee = action.payload.result.employee;
        state.document.storage = action.payload.result.storage;
        state.document.toStorage = action.payload.result.toStorage;
        state.document.comment = action.payload.result.comment;
        state.document.documentItems = action.payload.result.form;
        state.commentFiles = action.payload.result.files;
      })
      .addCase(setNewDocumentInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;

        if (amounts.length) {
          const userPositions = amounts.map((amountsItem) => ({
            address: amountsItem.address,
            amount: ''
          }));
          const documentItems = [...state.document.documentItems];
          const selectedItemIndex = documentItems.findIndex((item) => item.name === plantName);

          documentItems[selectedItemIndex].amounts = amounts;
          documentItems[selectedItemIndex].userPositions = userPositions;
          documentItems[selectedItemIndex].totalAmount = amounts.reduce((res, item) => res + Number(item.amount), 0);
          state.document.documentItems = documentItems;
        }
      })
      .addCase(fetchGoodsByStorageAsyncAction.fulfilled, (state, action) => {
        state.goods = action.payload.result;
      })
      .addCase(fetchGoodsByStorageFromHistoryAsyncAction.fulfilled, (state, action) => {
        state.goods = action.payload.result;
      })
      .addCase(provideInBruAsyncAction.fulfilled, (state, action) => {
        if (action.payload.form.unavailableForChargeGoods && action.payload.form.unavailableForChargeGoods.length) {
          state.unavailableForChargeGoods = action.payload.form.unavailableForChargeGoods;
        }
      })
      .addCase(fetchCorrectionsAsyncAction.fulfilled, (state, action) => {
        state.list = action.payload.result;
      })
      .addCase(fetchDocumentsNumbersAsyncAction.fulfilled, (state, action) => {
        state.documentsNumbers = action.payload;
      })
      .addCase(fetchStorageAddressesAsyncAction.fulfilled, (state, action) => {
        state.addresses = action.payload.result;
      });
  }
});

export const {
  resetDocumentAction,
  setDocumentAction,
  setDocumentDateAction,
  setNewDocumentDateAction,
  setDocumentNumberAction,
  setDocumentStorageAction,
  setDocumentDestinationStorageAction,
  setDocumentEmployeeAction,
  setDocumentCommentAction,
  setCommentFilesAction,
  setDocumentItemsAction,
  setUnavailableForChargeGoodsAction
} = documents.actions;
