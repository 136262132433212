import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchRealizationsListAsyncAction = createAsyncThunk(
  'realizations/fetchRealizationsList',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Realizations.GET_REALIZATIONS_LIST);

    return data;
  }
);

export const fetchArchiveRealizationsListAsyncAction = createAsyncThunk(
  'realizations/fetchArchiveRealizationsList',
  async (_args, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Realizations.GET_ARCHIVE_REALIZATIONS_LIST);

    return data;
  }
);

export const parseFileAndFetchDataAsyncAction = createAsyncThunk(
  'realizations/parseFileAndFetchData',
  async (formData, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Realizations.PARSE_FILE_AND_FETCH_DATA, formData);

    return data;
  }
);

export const fetchRealizationAsyncAction = createAsyncThunk(
  'realizations/fetchRealization',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Realizations.GET_REALIZATION}/${id}`);

    return data;
  }
);

export const setRealizationInitialAmountsAsyncAction = createAsyncThunk(
  'realizations/setNewDocumentInitialAmounts',
  async (body, {extra: api}) => {
    // const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_AMOUNTS, body);
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

export const saveRealizationAsyncAction = createAsyncThunk(
  'realizations/saveRealization',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {data} = await api.post(`${ApiRoute.Realizations.SAVE_REALIZATION}/${id}`, body);

    return data;
  }
);

export const deleteRealizationAsyncAction = createAsyncThunk(
  'realizations/deleteRealization',
  async (id, {extra: api}) => {
    const {data} = await api.delete(`${ApiRoute.Realizations.DELETE_REALIZATION}/${id}`);

    return data;
  }
);

export const provideRealizationAsyncAction = createAsyncThunk(
  'realizations/provideRealization',
  async (id, {extra: api}) => {
    const {data} = await api.post(`${ApiRoute.Realizations.PROVIDE_REALIZATION}/${id}`);

    return data;
  }
);

export const denyRealizationAsyncAction = createAsyncThunk(
  'realizations/denyRealization',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {data} = await api.post(`${ApiRoute.Realizations.PROVIDE_REALIZATION}/${id}`, body);

    return data;
  }
);

export const provideInBusinessRuAsyncAction = createAsyncThunk(
  'realizations/provideInBusinessRu',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Realizations.SET_DONE}/${id}`);

    return data;
  }
);

export const setCommentFilesAsyncAction = createAsyncThunk(
  'realizations/setCommentFiles',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {data} = await api.post(`${ApiRoute.Realizations.SET_COMMENT_FILE}/${id}`, body);

    return data;
  }
);

export const removeCommentFileAsyncAction = createAsyncThunk(
  'realizations/removeCommentFile',
  async (body, {extra: api}) => {
    const {data} = await api.post(`${ApiRoute.Realizations.REMOVE_COMMENT_FILE}`, body);

    return data;
  }
);
