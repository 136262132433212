import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import FactoryFloatPanel from 'components/factory-float-panel/factory-float-panel';
import FactoryTable from 'components/factory-table/factory-table';
import Modal from 'components/Modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import FactoryCheckValuesModal from 'components/factory-check-values-modal/factory-check-values-modal';

import {
  deleteCommentFileAsyncAction,
  fetchFactoryAsyncAction,
  postCommentFilesAsyncAction,
  saveFactoryAsyncAction
} from 'redux/slices/factory/factory-api-actions';
import {getFactory} from 'redux/slices/factory/selectors';
import {
  resetState,
  setCommentFilesAction,
  setFactoryCommentAction,
  setFactoryDocumentAction
} from 'redux/slices/factory/factory';

import {AppContext} from 'providers/AppContextProvider';

import {getUser, saveNewFactoryDocumentIntoLocalStorage} from 'helpers/storage';

import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const FactoryDetails = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {setCrumbs} = useContext(AppContext);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  const [commentModalActive, setCommentModalActive] = useState(false);

  // Валидация формы
  const [sourceNameNotSelected, setSourceNameNotSelected] = useState(false);
  const [targetNameNotSelected, setTargetNameNotSelected] = useState(false);
  const [materialNameNotSelected, setMaterialNameNotSelected] = useState(false);
  const [sourceAmountNotInserted, setSourceAmountNotInserted] = useState(false);
  const [targetAmountNotInserted, setTargetAmountNotInserted] = useState(false);
  const [materialRateNotInserted, setMaterialRateNotInserted] = useState(false);
  const [employeeNotInserted, setEmployeeNotInserted] = useState(false);
  const [documentDateNotSelected, setDocumentDateNotSelected] = useState(false);
  const [factoryDateNotSelected, setFactoryDateNotSelected] = useState(false);
  const [positionAmountNotInserted, setPositionAmountNotInserted] = useState(false);
  const [positionAddressNotSelected, setPositionAddressNotSelected] = useState(false);

  // Модальное окно валидации таблиц
  const [checkValuesModalData, setCheckValuesModalData] = useState({});
  const [isCheckValuesModalActive, setCheckValuesModalActive] = useState(false);

  const factory = useSelector(getFactory);
  const subName = factory.sub_name;
  const documentNumber = factory.document_number;
  const documentDate = factory.document_date;
  const newDocumentDate = factory.newDocumentDate;
  const comment = factory.comment;
  const commentFiles = factory.files;
  const employee = factory.employee;
  const factoryDate = factory.factory_date;
  const lines = factory.form;
  const materials = factory.materials;
  const done = factory.done;

  // Обработчик ввода текста примечания
  const handleModalTextAreaChange = (evt) => {
    const textAreaValue = evt.currentTarget.value;
    dispatch(setFactoryCommentAction(textAreaValue));
  };

  // Отправка файлов примечания на сервер
  const sendCommentFiles = async (id, files) => {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    await dispatch(postCommentFilesAsyncAction({
      id,
      files: formData
    }));
  };

  // Обработчик кнопки закрытия модального окна примечания
  const handleCommentModalCloseButtonClick = async () => {
    const newFiles = commentFiles.filter((file) => file instanceof File);

    await dispatch(saveFactoryAsyncAction({
      id,
      subName,
      form: lines,
      materials,
      document: {
        number: documentNumber,
        date: documentDate,
        factoryDate: `${factoryDate.trim()} ${documentDate.split(' ')[1]}`
      },
      employee,
      comment
    }));
    if (id && newFiles.length) {
      await sendCommentFiles(id, newFiles);
    }

    setCommentModalActive(false);
  };

  // Обработчик добавления файла к примечанию в модальном окне
  const handleFileInputChange = async (evt) => {
    const inputFiles = Object.values(evt.currentTarget.files);
    const updatedFiles = [...commentFiles, ...inputFiles];

    dispatch(setCommentFilesAction(updatedFiles));
  };

  // Удаление файла из модального окна примечания
  const handleRemoveFileButtonClick = async ({url, index}) => {
    // Если удаляется ещё не загруженный на сервер файл, то удаляем из стейта
    if (index) {
      const updatedCommentFiles = commentFiles.filter((_file, fileIndex) => fileIndex !== index);
      dispatch(setCommentFilesAction(updatedCommentFiles));
    }
    // Если удаляется уже загруженный файл, то удаляем на сервере
    if (url) {
      await dispatch(deleteCommentFileAsyncAction({filename: url}));
      dispatch(fetchFactoryAsyncAction(id));
    }
  };

  // Запрос детальной информации о производстве
  const requestForDocumentDetails = async (factoryId) => {
    setIsPreloaderActive(true);
    await dispatch(fetchFactoryAsyncAction(factoryId));
    setIsPreloaderActive(false);
  };

  // Запрос детальной информации о производстве
  useEffect(() => {
    if (id) {
      requestForDocumentDetails(id);
    }
  }, [id, documentNumber]);

  // Запись хлебных крошек
  useEffect(() => {
    const crumbs = isInArchive
      ? [
          {name: 'Архивы', url: AppRoute.Archive.ALL},
          {name: 'Производство', url: AppRoute.Archive.FACTORIES},
          {name: `№ ${documentNumber}`, url: ''}
        ]
      : [
          {name: 'Производство', url: AppRoute.Document.FACTORIES},
          {name: `№ ${documentNumber}`, url: ''}
        ];

    setCrumbs(crumbs);
  }, [id, documentNumber]);

  // Очистка стейта после отмонтирования компонента
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  // Если уже есть начатый документ в localStorage, то продолжить его заполнение
  useEffect(() => {
    const incompletedFactoryDocument = JSON.parse(localStorage.getItem('newFactoryDocument'));
    if (incompletedFactoryDocument) {
      dispatch(setFactoryDocumentAction(incompletedFactoryDocument));
    }
  }, []);

  // Сохранение нового документа в localStorage
  useEffect(() => {
    if (!id && (
      lines[0]?.name ||
      materials[0]?.name ||
      commentFiles?.length ||
      subName ||
      employee ||
      comment ||
      factoryDate ||
      newDocumentDate
    )) {
      saveNewFactoryDocumentIntoLocalStorage(factory);
    }
  }, [factory]);

  // Защита от открытия архивного документа с помощью адресной строки в основном списке и наоборот
  // Если пользователь намеренно вводит в адресной строке "/factories/<id архивного документа>"
  useEffect(() => {
    const shouldGoToArchive = !isInArchive && done === 1;
    const shouldGoToGeneralList = isInArchive && done === 0;

    if (shouldGoToArchive || shouldGoToGeneralList) {
      const route = isInArchive
        ? `${AppRoute.Document.FACTORIES}/${id}`
        : `${AppRoute.Archive.FACTORIES}/${id}`;

      navigate(route);
    }
  }, [done]);

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <FactoryFloatPanel
        setCommentModalActive={setCommentModalActive}
        setIsPreloaderActive={setIsPreloaderActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setCheckValuesModalActive={setCheckValuesModalActive}
        setSourceNameNotSelected={setSourceNameNotSelected}
        setTargetNameNotSelected={setTargetNameNotSelected}
        setMaterialNameNotSelected={setMaterialNameNotSelected}
        setSourceAmountNotInserted={setSourceAmountNotInserted}
        setTargetAmountNotInserted={setTargetAmountNotInserted}
        setMaterialRateNotInserted={setMaterialRateNotInserted}
        setEmployeeNotInserted={setEmployeeNotInserted}
        setDocumentDateNotSelected={setDocumentDateNotSelected}
        setFactoryDateNotSelected={setFactoryDateNotSelected}
        setPositionAmountNotInserted={setPositionAmountNotInserted}
        setPositionAddressNotSelected={setPositionAddressNotSelected}
      />

      <FactoryTable
        sourceNameNotSelected={sourceNameNotSelected}
        targetNameNotSelected={targetNameNotSelected}
        materialNameNotSelected={materialNameNotSelected}
        setSourceNameNotSelected={setSourceNameNotSelected}
        setTargetNameNotSelected={setTargetNameNotSelected}
        setMaterialNameNotSelected={setMaterialNameNotSelected}
        sourceAmountNotInserted={sourceAmountNotInserted}
        setSourceAmountNotInserted={setSourceAmountNotInserted}
        targetAmountNotInserted={targetAmountNotInserted}
        setTargetAmountNotInserted={setTargetAmountNotInserted}
        materialRateNotInserted={materialRateNotInserted}
        setMaterialRateNotInserted={setMaterialRateNotInserted}
        employeeNotInserted={employeeNotInserted}
        setEmployeeNotInserted={setEmployeeNotInserted}
        documentDateNotSelected={documentDateNotSelected}
        setDocumentDateNotSelected={setDocumentDateNotSelected}
        factoryDateNotSelected={factoryDateNotSelected}
        setFactoryDateNotSelected={setFactoryDateNotSelected}
        positionAmountNotInserted={positionAmountNotInserted}
        setPositionAmountNotInserted={setPositionAmountNotInserted}
        positionAddressNotSelected={positionAddressNotSelected}
        setPositionAddressNotSelected={setPositionAddressNotSelected}
        setIsPreloaderActive={setIsPreloaderActive}
      />

      <Modal isActive={commentModalActive} setIsActive={handleCommentModalCloseButtonClick}>
        {
          !isInArchive && canUserUpdate ? (
            <>
              <textarea
                value={comment}
                onChange={handleModalTextAreaChange}
                placeholder="Примечание"
              />
              <input
                type="file"
                name="files[]"
                onChange={handleFileInputChange}
                multiple
              />
            </>
          ) : (
            <div className={styles.commentFrame}>
              {comment}
            </div>
          )
        }
        {
          commentFiles.map((file, index) => (
            <div className="fileIconGroup">
              {
                !isInArchive && canUserUpdate && (
                  <span
                    className="material-icons remove"
                    title="Удалить"
                    onClick={() => handleRemoveFileButtonClick(file instanceof File ? {index} : {url: file.url})}
                  >
                    cancel
                  </span>
                )
              }
              <span
                className="material-icons file"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                description
              </span>
              <span
                className="name"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                {file.name}
              </span>
            </div>
          ))
        }
      </Modal>

      <FactoryCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setCheckValuesModalActive}
      />
    </>
  );
};

export default FactoryDetails;
