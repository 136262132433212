export const factoryFormEmptyLine = {
      good_id: null,
      name: '',
      article: '',
      b_group: '',
      good_id_after: null,
      name_after: '',
      article_after: '',
      container: '',
      b_group_id: null,
      totalAmount: 0,
      totalAmount_before: 0,
      totalAmount_after: 0,
      amounts: [
        {
          amount: 0,
          address: ''
        }
      ],
      userPositions: [
        {
          amount: 0,
          address: '',
          amount_after: 0,
          address_after: ''
        }
      ]
};
