import {useContext, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import cl from 'styles/components/cabinet/UsersTable.module.scss';

import Indicator from 'components/Indicator';
import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';

import {fetchRealizations} from 'redux/slices/docSlice';

import {AppContext} from 'providers/AppContextProvider';

import {DocumentType} from 'constants/document-type';

const Index = () => {
	const dispatch = useDispatch();
  const navigate = useNavigate();
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  const {realizations} = useSelector((state) => state.doc);

  const {setCrumbs} = useContext(AppContext);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Отгрузки', url: ''}
		]);
	}, []);

  useEffect(() => {
    dispatch(fetchRealizations());
  }, []);
	
	return (
		<>
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Отгрузка создана &nbsp;
				<Indicator type="success"/> - Проведено в Системе
			</div>

			{
        !isInArchive && (
          <DocumentsListButtonsBlock documentType={DocumentType.REALIZATION}/>
        )
      }
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>Системный №</th>
						<th>Заказ</th>
						<th>Клиент</th>
						<th>Дата в Системе</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
						realizations.data.map((realization, index) =>
							<tr key={index.toString()}>
								<td>
									<span>{realization.document_number}</span>
								</td>
								<td>
									<span>{realization.number}</span>
								</td>
								<td>
									<span>{realization.customer}</span>
								</td>
								<td>
									<span>{realization.document_date}</span>
								</td>
								<td>
									<span
										className={`${cl.icon} material-icons`}
										title="Посмотреть"
										onClick={() => navigate(`/realizations/edit/${realization.id}`)}
									>
										visibility
									</span>
								</td>
								<td>
									<Indicator type={realization.provided === 1 ? 'success' : 'danger'}/>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</>
	)
}

export default Index;
