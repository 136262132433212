export const DocumentType = {
  CHARGE: 'charge',
  POSTING: 'posting',
  SUPPLY: 'supply',
  SHIFTING: 'shifting',
  INVENTORY: 'inventory',
  REALIZATION: 'realization',
  FACTORY: 'factory'
};

export const DocumentTypeNameMap = {
  [DocumentType.CHARGE]: 'Списание',
  [DocumentType.POSTING]: 'Оприходование',
  [DocumentType.SUPPLY]: 'Поступление',
  [DocumentType.SHIFTING]: 'Перемещение',
  [DocumentType.INVENTORY]: 'Инвентаризация',
  [DocumentType.REALIZATION]: 'Отгрузка',
  [DocumentType.FACTORY]: 'Производство'
};
