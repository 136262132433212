import clsx from 'clsx';

import styles from './styles.module.scss';

const PopUp = ({
  children,
  setPopUpVisible = false,
  position = 'top-left'
}) => {
  const handleCloseButtonClick = () => {
    setPopUpVisible(false);
  };

  return (
    <div className={clsx(styles.popup, {
      [styles['top-right']]: position === 'top-right'
    })}>
      <div className={clsx(styles.anchor, {
      [styles['top-right']]: position === 'top-right'
    })}></div>
      <div className={styles.message}>
        {children}
      </div>
      <div className={styles.controls}>
        <button
          onClick={handleCloseButtonClick}
          className={styles['close-button']}
        >
          <span className='material-icons'>
            close
          </span>
        </button>
      </div>
    </div>
  );
};

export default PopUp;
