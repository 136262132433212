import {Fragment, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import {setNewDocumentInitialAmountsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {setDocumentItemsAction} from 'redux/slices/documents/documents';
import {
  getDestinationStorage,
  getDocument,
  getNewDocumentDate,
  getProvidedStatus
} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {sortStringValues} from 'helpers/utils';

import styles from './styles.module.scss';
import toastMessageStyles from 'styles/components/toast-message.module.scss';

const ShiftingsTableRowUpdated = ({
  line,
  lineIndex,
  goods,
  groups,
  addresses,
  deleteLine
}) => {
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const document = useSelector(getDocument);
  const documentDate = document.documentDate;
  const newDocumentDate = useSelector(getNewDocumentDate);
  const provided = !!useSelector(getProvidedStatus);
  const storage = document.storage;
  const destinationStorage = useSelector(getDestinationStorage);
  const documentItems = document.documentItems;
  const itemsNames = structuredClone(documentItems).map((itm) => itm.name);

  // Список существующих и добавленных адресов в подстроках
  // для исключения их из выпадающего списка выбора адреса в подстроке
  const existingItemAddresses = line.userPositions.map((position) => position.address);
  const displayedAddresses = addresses.filter((address) => !existingItemAddresses.includes(address.value));

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = goods
      .filter((plant) => !itemsNames.includes(plant.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (line.b_group && groups.length) {
      const groupId = groups.find((gr) => gr.name === line.b_group).id;
      const filteredByGroupPlants = filteredPlants
        .filter((plant) => plant.b_group_id === groupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    const copiedLines = structuredClone(documentItems)
      .map((item) => ({
        ...item,
        storage,
        toStorage: destinationStorage
      }));

    copiedLines[index][selectElementName] = value;

    if (selectElementName === 'name') {
      const plant = goods.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const businessGroupName = plant.b_group;
      const plantBruGoodId = plant.b_good_id;

      copiedLines[index].article = plantArticle;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }
    if (selectElementName === 'article') {
      const plant = goods.find((plant) => plant.article === value);
      const plantName = plant.name;
      const businessGroupName = plant.b_group;
      const plantBruGoodId = plant.b_good_id;

      copiedLines[index].name = plantName;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }

    dispatch(setDocumentItemsAction(copiedLines));

    // Если товар выбран (наименование определено), то запрашиваем данные с полей
    if (copiedLines[index].name) {
      dispatch(setNewDocumentInitialAmountsAsyncAction({
        name: copiedLines[index].name,
        storage,
        date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
      }));
    }
  };

  // Обработчик выбора адреса
  const handleAddressChange = (positionIndex, address) => {
    if (documentItems[lineIndex].name) {
      const copiedLines = structuredClone(documentItems);

      copiedLines[lineIndex].userPositions[positionIndex].address_after = address;
      copiedLines[lineIndex].userPositions[positionIndex].address_current = copiedLines[lineIndex].userPositions[positionIndex].address;

      dispatch(setDocumentItemsAction(copiedLines));
    } else {
      toast.error('Выберите наименование', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });

      return true;
    }
  };

  // Изменение позиций
  const handlePositionDataInputChange = (indexes, value) => {
    if (!!documentItems[indexes.lineIndex].name) {
      const copy = structuredClone(documentItems);
      const selectedValue = value > 0 ? value : 0;

      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount = selectedValue;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_current = selectedValue;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_after = selectedValue;
      copy[indexes.lineIndex].totalAmount_after = copy[indexes.lineIndex].userPositions.reduce((res, item) => res + Number(item.amount), 0);

      const totalAmountAfter = copy[indexes.lineIndex].userPositions.reduce((res, item) => res + Number(item.amount_after), 0);
      copy[indexes.lineIndex].totalAmount_after = totalAmountAfter;

      dispatch(setDocumentItemsAction(copy));
    } else {
      const copy = structuredClone(documentItems);

      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount = 0;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_current = 0;
      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount_after = 0;

      dispatch(setDocumentItemsAction(copy));

      toast.error('Выберите наименование', {
        position: 'bottom-right',
        className: toastMessageStyles['toast-message']
      });
    }
  };

  // Удаление подстроки (поле/площадка)
  const deleteSubstring = (positionIndex) => {
    const copiedLines = structuredClone(documentItems);

    copiedLines[lineIndex].userPositions.splice(positionIndex, 1);

    dispatch(setDocumentItemsAction(copiedLines));
  };

  // Обработчик удаления подстроки (поле/площадка)
  const handleDeleteSubstringButtonClick = (positionIndex) => {
    const handler = () => {
      deleteSubstring(positionIndex);
    };

    alert('Удалить площадку?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  const handleAddNewSubstringButtonClick = (position, positionIndex) => {
    const newSubstring = {
      address: position.address,
      amount: 0,
      amount_current: 0,
      amount_after: 0,
      address_after: '',
      address_current: position.address_current
    };
    const updatedUserPositions = [
      ...documentItems[lineIndex].userPositions.slice(0, positionIndex + 1),
      newSubstring,
      ...documentItems[lineIndex].userPositions.slice(positionIndex + 1)
    ];
    const updatedLines = structuredClone(documentItems);

    updatedLines[lineIndex].userPositions = updatedUserPositions;

    dispatch(setDocumentItemsAction(updatedLines));
  };

  // Для правильной отрисовки ячеек "Кол-во" и "Адрес" в "Остатки до перемещения:"
  // и кнопки добавления новой подстроки для адреса
  const indexesForAddNewSubstringButton = [];
  const indexesForExistingAddressesCell = [];
  let currentAddress = null;
  line.userPositions.forEach((position, index) => {
    if (position.address !== currentAddress) {
      indexesForExistingAddressesCell.push(index);
      if (index !== 0) {
        indexesForAddNewSubstringButton.push(index - 1);
      }
      currentAddress = position.address;
    }
  });

  return (
    <Fragment>
      {
        line.userPositions.map((position, positionIndex, userPositions) => (
          <tr key={positionIndex} className={line.isError ? styles.error : ''}>
            {
              positionIndex === 0 && (
                <>
                  {/* Ячейка № п/п */}
                  <td rowSpan={userPositions.length}>{lineIndex + 1} ({line.good_id})</td>

                  {/* Ячейка "Наименование" */}
                  <td
                    rowSpan={userPositions.length}
                    className={styles.name}
                  >
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='name'
                          defaultValue={line.name}
                          options={filterSelectComponentOptions('name')}
                          onChange={(value) => changeValue(lineIndex, 'name', value)}
                          placeholder='Наименование'
                          searchMode={true}
                        />
                      ) : (
                        line.name
                      )
                    }
                  </td>

                  {/* Ячейка "Артикул" */}
                  <td rowSpan={userPositions.length}>
                    {
                      !provided && !line.article ? (
                        <CustomSearchSelect
                          inputName='article'
                          defaultValue={line.article}
                          options={filterSelectComponentOptions('article')}
                          onChange={(value) => changeValue(lineIndex, 'article', value)}
                          placeholder='Артикул'
                          searchMode={true}
                        />
                      ) : (
                        line.article
                      )
                    }
                  </td>

                  {/* Ячейка "Группа" */}
                  <td rowSpan={userPositions.length}>
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='b_group'
                          defaultValue={line.b_group}
                          options={groups}
                          onChange={(value) => changeValue(lineIndex, 'b_group', value)}
                          placeholder='Группа'
                          searchMode={true}
                        />
                      ) : (
                        line.b_group
                      )
                    }
                  </td>

                  {/* Ячейка общего кол-ва товара на выбранном складе "Всего" */}
                  <td rowSpan={userPositions.length} className={styles.right}>
                    {new Intl.NumberFormat('ru-RU').format(line.totalAmount)}
                  </td>
                </>
              )
            }

            {/* Кол-во и адрес из БД - реальное кол-во на полях/площадках */}
            {
              indexesForExistingAddressesCell.includes(positionIndex) && (
                <>
                  <td
                    rowSpan={userPositions.filter((item) => position.address === item.address).length}
                  >
                    {new Intl.NumberFormat('ru-RU').format(line.amounts.find((amountsItem) => amountsItem.address === position.address) ? line.amounts.find((amountsItem) => amountsItem.address === position.address).amount : 0)}
                  </td>
                  <td
                    rowSpan={userPositions.filter((item) => position.address === item.address).length}
                  >
                    {line.amounts.find((amountsItem) => amountsItem.address === position.address) ? line.amounts.find((amountsItem) => amountsItem.address === position.address).address : ''}
                  </td>
                </>
              )
            }

            {/* Ячейка ввода перемещаемого кол-ва */}
            <td className={styles.right}>
              {
                !provided && canUserUpdate ? (
                  <input
                    type='number'
                    name='amount_current'
                    placeholder='Кол-во'
                    value={position.amount_current ? position.amount_current : 0}
                    onChange={(evt) => handlePositionDataInputChange({lineIndex, positionIndex}, evt.currentTarget.value)}
                    autoComplete='off'
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    position.amount_current ?? 0
                  )
                )
              }
            </td>

            {/* Ячейка ввода оприходуемого кол-ва */}
            <td className={styles.right}>
              {
                !provided && canUserUpdate ? (
                  <input
                    type='number'
                    name='amount_after'
                    placeholder='Кол-во'
                    value={position.amount_after ? position.amount_after : 0}
                    onChange={(evt) => handlePositionDataInputChange({lineIndex, positionIndex}, evt.currentTarget.value)}
                    autoComplete='off'
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    position.amount_after ?? 0
                  )
                )
              }
            </td>

            {/* Ячейка выбора адреса прихода */}
            <td>
              <div className={styles.subLine}>
                {
                  !provided &&
                  !line.amounts
                    .filter((amountsItem) => amountsItem.address !== '')
                    .find((amountsItem) => amountsItem.address === position.address_after) ? (
                    <CustomSearchSelect
                      inputName='address_after'
                      defaultValue={position.address_after}
                      options={displayedAddresses}
                      onChange={(value) => handleAddressChange(positionIndex, value)}
                      placeholder='Адрес'
                      executeChangeHandlerOnTypingValue={true}
                    />
                  ) : (
                    position.address_after
                  )
                }
              </div>
            </td>

            {/* Ячейка "Всего:" в "Остатки после перемещения" */}
            {
              positionIndex === 0 && (
                <td rowSpan={userPositions.length} className={styles.right}>
                  {new Intl.NumberFormat("ru-RU").format(
                    line.totalAmount_after ? Number(line.totalAmount_after) : 0
                  )}
                </td>
              )
            }

            <td className={styles.right}>
              {new Intl.NumberFormat("ru-RU").format(position.amount_after ? Number(position.amount_after) : 0)}
            </td>

            {/* Ячейка адреса прихода */}
            <td>
              <div className={clsx(styles.subLine, {
                [styles.stretch]: !!position.address_after
              })}>
                {position.address_after}
                <div className={styles.buttonsWrapper}>
                  {
                    (indexesForAddNewSubstringButton.includes(positionIndex) || positionIndex === userPositions.length - 1) && !provided && (
                      <td>
                        <span
                          className='material-icons'
                          onClick={() => handleAddNewSubstringButtonClick(position, positionIndex)}
                          title='Добавить адрес'
                        >
                          add
                        </span>
                      </td>
                    )
                  }
                  {
                    userPositions.filter((item) => item.address === position.address).length > 1 && !provided && (
                      <span
                        className='material-icons'
                        onClick={() => handleDeleteSubstringButtonClick(positionIndex)}
                        title='Удалить площадку'
                      >
                        delete_outline
                      </span>
                    )
                  }
                </div>
              </div>
            </td>

            {/* Кнопка удаления товара из документа */}
            {
              positionIndex === 0 && !provided && (
                <td className='iconed' rowSpan={userPositions.length}>
                  <span
                    className='roundIcon material-icons'
                    onClick={() => deleteLine(lineIndex)}
                    title='Удалить строку'
                  >
                    delete_outline
                  </span>
                </td>
              )
            }
          </tr>
        ))
      }
    </Fragment>
  );
};

export default ShiftingsTableRowUpdated;
