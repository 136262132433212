import {Fragment, useEffect, useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import axios from "axios";

// import SpinPlaceholderComponent from "components/preloader/SpinPlaceholderComponent";
import Filter from "components/Filter";
import InputComponent from "components/input/InputComponent";
import CustomSearchSelect from "components/custom-search-select/custom-search-select";

import {fetchField, fetchFieldHead} from "redux/slices/fieldSlice";
import {fetchGroups} from "redux/slices/business/businessSlice";
import {addHistoryAsyncAction} from "redux/slices/history/history-api-actions";

import {AppContext} from "providers/AppContextProvider";

import {getUser} from "helpers/storage";
import {generateDate} from "helpers/utils";
// import {loadingStatus} from "helpers/fetcher";

import cl from "styles/pages/[fieldId].module.scss";

const FieldId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: mapId, field_id: fieldId} = useParams();

  // Приходящие данные
  const [head, setHead] = useState({});
  const [plants, setPlants] = useState([]);
  const [initialStatePlants, setInitialStatePlants] = useState([]);

  // Поле сортировки и направление
  const [sorted, setSorted] = useState("name");
  const [sortDir, setSortDir] = useState(false);

  // Редактирование: флаги и данные
  const [isEditing, setIsEditing] = useState(false);
  const [isHeadEdited, setIsHeadEdited] = useState(false);
  const [editedGoods, setEditedGoods] = useState([]);
  const [deleted, setDeleted] = useState([]);

  // Показ фильтра
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Общий остаток
  const [totalAmount, setTotalAmount] = useState(0);

  // Классы таблиц
  const tableClasses = [cl.mainTable, "table", "table-responsive"];

  // Данные для фильтров
  const [plantsNames, setPlantsNames] = useState([]);
  const [plantsArticles, setPlantsArticles] = useState([]);
  
  const {alert, setMenuCondition, setCrumbs} = useContext(AppContext);
	const {field, fieldHead, addressList} = useSelector((state) => state.field);
	const {groups} = useSelector((state) => state.field);

  // Вывод уведомления при переходе по ссылке, если есть несохраненные данные
  useEffect(() => {
    if (!isHeadEdited && !deleted.length) {
      setMenuCondition({});
      return;
    }

    // Установка условия с обязательной очисткой после перехода по ссылке
    setMenuCondition({
      text: "Есть несохраненные данные. Сохранить?",
      type: "danger",
      duration: 10000,
      buttons: [
        {
          text: "Да",
          handler: function (href) {
            editing();
            navigate(href).then(() => {
              setMenuCondition({});
            });
          },
        },
        {
          text: "Нет",
          handler: function (href) {
            alert("", "danger", 1);
            navigate(href).then(() => {
              setMenuCondition({});
            });
          },
        },
      ],
    });
  }, [isHeadEdited, deleted.length]);

  useEffect(() => {
    // Подсчет общего остатка
    let amount = 0;
    plants.map((plant) => {
      amount += plant.amount;
    });
    setTotalAmount(amount);
  }, [plants]);

  useEffect(() => {
    // Запись хлебных крошек
    setCrumbs([
      { name: head.map_name, url: "/map/" + head.map_id },
      { name: "Поле / площадка: " + head.name, url: "" },
    ]);

    // Запись наименований и артикулов для фильтра
    if (!plantsNames.length)
      plants.map((pl) => {
        plantsNames.push({ name: pl.name, value: pl.name });
        plantsArticles.push({ name: pl.article, value: pl.article });
      });
  }, [head, plants]);

  useEffect(() => {
    dispatch(fetchField({ id: fieldId, q: `?map_id=${mapId}` }));
    dispatch(fetchFieldHead({ id: fieldId, q: `?map_id=${mapId}` }));
    dispatch(fetchGroups());
  }, [mapId, fieldId]);

  useEffect(() => {
    if (fieldHead.data) {
      setHead(fieldHead.data);
    }
  }, [fieldHead]);

  useEffect(() => {
    if (field.data) {
      setPlants(field.data);
      setInitialStatePlants(field.data);
    }
  }, [field]);

  // Вкл/выкл редактирование и отправка данных
  const editing = () => {
    // Вкл/выкл редактирование
    if (!isEditing) {
      setIsEditing(true);
      return false;
    }

    // Флаг о показанном уведомлении, чтобы не дублировать
    let showedAlertSuccess = false;

    // Отправка новой шапки, если были изменения
    if (isHeadEdited) {
      axios
        .put(process.env.REACT_APP_SERVER_ENTRYPOINT + "/fields/updateFieldHead/" + head.id, head)
        .then(() => {
          alert("Успешно сохранено", "success");
          showedAlertSuccess = true;
          setIsHeadEdited(false);
          setIsEditing(false);
        })
        .catch((error) => {
          if (error.response.data) alert(error.response.data.message, "danger");
          else alert("Возникли ошибки со стороны сервера", "danger");
        });
    }

    // Отправка измененных данных
    if (editedGoods.length > 0) {
      const uniqueEditedPositions = []; // {index: number; ind: number}
      const uniqueEditedItems = [];     // {index: number; ind: number}
      editedGoods.forEach((editedItem) => {
        const existingUniqueEditedPosition = uniqueEditedPositions.find((item) => {
          return item.index === editedItem.index && item.ind === editedItem.ind;
        });
        const existingUniqueEditedItem = uniqueEditedItems.find((item) => {
          return item.index === editedItem.index;
        });

        if (!existingUniqueEditedPosition) {
          uniqueEditedPositions.push(editedItem);
        }
        if (!existingUniqueEditedItem) {
          uniqueEditedItems.push(editedItem);
        }
      });

      const editedPositions = [];
      uniqueEditedPositions.forEach((uniqueEditedPosition) => {
        const isPlantAdded = editedPositions.some((plant) => plant.name === plants[uniqueEditedPosition.index].name);

        if (!isPlantAdded) {
          editedPositions.push(plants[uniqueEditedPosition.index]);
        }
      });

      const historyItems = structuredClone(uniqueEditedItems).map((historyItem) => {
        const historyItemAmount = plants[historyItem.index].amount;
        const historyItemInitialAmount = initialStatePlants[historyItem.index].amount;

        if (historyItemAmount === historyItemInitialAmount) {
          const changedPositions = plants[historyItem.index].positions.map((positionsItem, positionIndex) => {
            const currentAmount = positionsItem.amount;
            const initialAmount = initialStatePlants[historyItem.index].positions[positionIndex].amount;
            
            return {
              address: positionsItem.address,
              difference: currentAmount - initialAmount
            };
          });

          return {
            ...plants[historyItem.index],
            initialAmount: historyItemInitialAmount,
            changedPositions
          }
        }

        return {
          ...plants[historyItem.index],
          initialAmount: historyItemInitialAmount
        }
      });

      axios
        .post("fields/updateFieldData", {
          lines: editedPositions,
        })
        .then(() => {
          alert("Успешно сохранено", "success");
          editedGoods.length = 0;
          setIsEditing(false);

          // Записать в историю изменения
          // dispatch(addHistoryAsyncAction({
          //   history: historyItems.map((historyItem) => {
          //     let system_docs = '';

          //     if (historyItem.amount > historyItem.initialAmount) {
          //       system_docs = 'Коррекция (приход)';
          //     }
          //     if (historyItem.amount < historyItem.initialAmount) {
          //       system_docs = 'Коррекция (расход)';
          //     }
          //     if (historyItem.amount === historyItem.initialAmount) {
          //       const details = historyItem.changedPositions
          //         .map((changedPosition) => {
          //           if (changedPosition.difference < 0) {
          //             return `расход по адресу ${changedPosition.address} в кол-ве ${-changedPosition.difference} шт.`;
          //           }
          //           if (changedPosition.difference > 0)  {
          //             return `приход по адресу ${changedPosition.address} в кол-ве ${changedPosition.difference} шт.`;
          //           } else {
          //             return '';
          //           }
          //         })
          //         .filter((item) => item !== '')
          //         .join(' ');

          //       system_docs = `Коррекция (${details})`;
          //     }

          //     return {
          //       field_id: historyItem.field_id,
          //       row: historyItem.row,
          //       b_group: historyItem.b_group,
          //       article: historyItem.article,
          //       name: historyItem.name,
          //       date: generateDate(),
          //       count_start: historyItem.initialAmount,
          //       count_plus: historyItem.amount > historyItem.initialAmount ? historyItem.amount - historyItem.initialAmount : 0,
          //       count_minus: historyItem.amount < historyItem.initialAmount ? historyItem.initialAmount - historyItem.amount : 0,
          //       count_end: historyItem.amount,
          //       docs: '',
          //       doc_id: '',
          //       doc_line_id: '',
          //       system_docs,
          //       system_doc_id: '',
          //       good_id: historyItem.good_id
          //     };
          //   })
          // }));
        })
        .then(() => {
          // Обновить данные
          dispatch(fetchField({ id: fieldId, q: `?map_id=${mapId}` }));
        })
        .catch(() => {
          alert("Ошибка сохранения", "danger");
        });
    }

    // Удаление строк, сохраненных в состоянии
    if (deleted.length > 0) {
      axios
        .post("fields/deleteFieldData", {
          deleted
        })
        .then(() => {
          if (!showedAlertSuccess) alert("Успешно сохранено", "success");
          deleted.length = 0;
          setIsEditing(false);

          // Записать в историю по всем удалённым товарам
        })
        .catch(() => {
          alert("Возникли ошибки со стороны сервера", "danger");
        });
    }
  };

  // Удаление поля
  const deleteField = () => {
    if (getUser().accessLevel < 2) return;

    const btns = [
      {
        text: "Да",
        handler: function () {
          axios.delete("map/deleteField/" + head.id).then(() => {
            alert("", "danger", 1);
            navigate("/map/" + head.map_id);
          });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "danger", 1);
        },
      },
    ];
    alert("Удалить поле?", "danger", 10000, btns);
  };

  // Редактирование шапки поля
  const headEditor = (param, value) => {
    const copy = structuredClone(head);
    copy[param] = value;
    setHead(copy);
    setIsHeadEdited(true);
  };

  // Удаление строки из таблицы
  const deleteLine = (index) => {
    const deletedId = plants[index.index].positions[index.ind].id;
    setDeleted((prevState) => [...prevState, deletedId]);

    const copiedPlants = structuredClone(plants);
    copiedPlants[index.index].positions.splice(index.ind, 1);
    setPlants(copiedPlants);
  };

  // Функция сортировки растений
  const sortPlants = (name) => {
    setSortDir(!sortDir);

    const pl = structuredClone(plants);

    pl.sort((a, b) => {
      if (a[name] === b[name]) return 0;
      else if (!sortDir === true) return a[name] > b[name] ? 1 : -1;
      else return a[name] < b[name] ? 1 : -1;
    });

    setPlants(pl);
    setSorted(name);
  };

  // Печать (скачивание) данных поля
  const print = () => {
    axios
      .post("fields/printFieldTable", {
        head: head,
        body: plants,
        totalAmount: totalAmount,
      })
      .then((response) => {
        navigate("/FILES/" + response.data.filename).then(() => {
          navigate("/map/" + head.map_id + "/fields/" + head.map_field_id);
        });
      })
      .catch(() => {
        alert("Ошибка скачивания", "danger");
      });
  };

  // Метод изменения данных
  const onChangePos = (index, name, value) => {
    setEditedGoods((prevState) => ([
      ...prevState,
      index
    ]));

    const copy = structuredClone(plants);
    if (name === "amount") {
      copy[index.index].amount += value - copy[index.index].positions[index.ind].amount;
    }
    copy[index.index].positions[index.ind][name] = Number(value);
    setPlants(copy);
  };

  return (
    <>
      <div className={cl.floatPanel + " " + cl.controlsPanel}>
        {isEditing && getUser().accessLevel > 2 && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={deleteField}
            title="Удалить поле"
          >
            delete_sweep
          </span>
        )}

        <span
          className="roundIcon material-icons"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          title="Фильтр"
        >
          filter_alt
        </span>

        <span
          className="roundIcon material-icons"
          onClick={print}
          title="Скачать"
        >
          download
        </span>

        {
          getUser().accessLevel > 1 && (
            <span
              className="roundIcon material-icons"
              onClick={() =>
                !isEditing
                  ? editing()
                  : alert("Сохранить?", "default", 10000, [
                      {
                        text: "Да",
                        handler: function () {
                          alert("", "default", 1);
                          editing();
                        },
                      },
                      {
                        text: "Нет",
                        handler: function () {
                          alert("", "default", 1);
                        },
                      },
                    ])
              }
              title={isEditing ? "Сохранить" : "Редактировать"}
            >
              {isEditing ? "save" : "edit_note"}
            </span>
          )
        }

        <span
          className="roundIcon material-icons"
          onClick={() => window.open("/faq/field", "_blank")}
          title="Инструкция"
        >
          quiz
        </span>
      </div>

      <div
        className="stickyContainer"
        onClick={() => {
          if (isFilterOpen) setIsFilterOpen(false);
        }}
      >
        <table className={tableClasses.join(" ") + " " + cl.fixed}>
          <thead className="thead-dark">
            <tr>
              <th>
                Поле/Площадка:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="name"
                    placeholder="Название"
                    value={head.name}
                    setValue={headEditor}
                  />
                ) : (
                  head.name
                )}
              </th>
              <th>
                Склад:&nbsp;
                {
                  isEditing && getUser().accessLevel > 1 ? (
                    <CustomSearchSelect
                      inputName='storage'
                      defaultValue={head.storage}
                      options={head.map_storages}
                      onChange={(value) => headEditor('storage', value)}
                      placeholder='Склад'
                    />
                  ) : (
                    head.storage
                  )
                }
              </th>
              <th>
                Длина:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="length"
                    placeholder="Длина поля"
                    value={head["length"]}
                    setValue={headEditor}
                  />
                ) : (
                  head["length"]
                )}
                м
              </th>
              <th>
                Ширина:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="width"
                    placeholder="Ширина поля"
                    value={head["width"]}
                    setValue={headEditor}
                  />
                ) : (
                  head["width"]
                )}
                м
              </th>
              <th>
                Общая площадь:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="square_total"
                    placeholder="Общая площадь"
                    value={head["square_total"]}
                    setValue={headEditor}
                  />
                ) : (
                  head["square_total"]
                )}
                га
              </th>
              <th>
                Полезная площадь:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="square_plant"
                    placeholder="Полезная площадь"
                    value={head["square_plant"]}
                    setValue={headEditor}
                  />
                ) : (
                  head["square_plant"]
                )}
                га
              </th>
            </tr>
            <tr>
              <th>
                Кол-во рядов:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="rows"
                    placeholder="Кол-во рядов"
                    value={head.rows}
                    setValue={headEditor}
                  />
                ) : (
                  head.rows
                )}
              </th>
              <th colSpan="2" className={cl.long}>
                Схема:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="plant_schema"
                    placeholder="Схема"
                    value={head.plant_schema}
                    setValue={headEditor}
                  />
                ) : (
                  head.plant_schema
                )}
              </th>
              <th colSpan="3" className={cl.long}>
                Ветрозащита:&nbsp;
                {isEditing && getUser().accessLevel > 1 ? (
                  <InputComponent
                    type="text"
                    name="wind"
                    placeholder="Ветрозащита"
                    value={head.wind}
                    setValue={headEditor}
                  />
                ) : (
                  head.wind
                )}
              </th>
            </tr>
            <tr>
              <th colSpan="6" className={cl.long}>
                Описание:&nbsp;
                {
                  isEditing && getUser().accessLevel > 1 ? (
                    <InputComponent
                      type="text"
                      name="description"
                      placeholder="Описание поля"
                      value={head.description}
                      setValue={headEditor}
                    />
                  ) : (
                    head.description
                  )
                }
              </th>
            </tr>
          </thead>
        </table>
        <table className={tableClasses.join(" ")}>
          <thead className="theadBordered thead-dark">
            <tr>
              <th colSpan="2" />
              <th colSpan="2">Всего по полю/площадке:</th>
              <th>{new Intl.NumberFormat("ru-RU").format(totalAmount)}</th>
              <th colSpan="2" />
              {isEditing && <th rowSpan="3" className={cl.borderNone} />}
            </tr>
            <tr>
              <th rowSpan="2">№</th>
              <th
                rowSpan="2"
                className={sorted === "name" ? "sort sorted" : "sort"}
                onClick={() => sortPlants("name")}
              >
                <span>Название</span>
              </th>
              <th
                rowSpan="2"
                className={sorted === "article" ? "sort sorted" : "sort"}
                onClick={() => sortPlants("article")}
              >
                <span>Артикул</span>
              </th>
              <th
                rowSpan="2"
                className={sorted === "b_group" ? "sort sorted" : "sort"}
                onClick={() => sortPlants("b_group")}
              >
                <span>Группа</span>
              </th>
              <th rowSpan="2">Кол-во всего</th>
              <th colSpan="2">В том числе:</th>
            </tr>
            <tr>
              <th>Кол-во</th>
              <th>Адрес</th>
            </tr>
          </thead>
          <tbody>
            {plants.length > 0 &&
              plants.map((plant, index) => (
                <Fragment key={plant.id}>
                  {plant.positions.map((pos, ind) => (
                    <tr key={ind}>
                      {ind === 0 && (
                        <>
                          <td
                            rowSpan={plant.positions.length}
                            className={
                              cl.cifer + " " + cl.center + " " + cl.router
                            }
                            onClick={() =>
                              navigate("/history?name=" + plant.name)
                            }
                          >
                            {index + 1}
                          </td>
                          <td
                            rowSpan={plant.positions.length}
                            className={cl.name + " " + cl.router}
                            onClick={() =>
                              navigate("/history?name=" + plant.name)
                            }
                          >
                            {plant.name}
                          </td>
                          <td
                            rowSpan={plant.positions.length}
                            className={cl.center + " " + cl.router}
                            onClick={() =>
                              navigate("/history?name=" + plant.name)
                            }
                          >
                            {plant.article}
                          </td>
                          <td rowSpan={plant.positions.length}>
                            {plant.b_group}
                          </td>
                          <td
                            rowSpan={plant.positions.length}
                            className={cl.meters}
                          >
                            {new Intl.NumberFormat("ru-RU").format(
                              plant.amount
                            )}
                          </td>
                        </>
                      )}

                      <td className={cl.meters}>
                        {
                          isEditing ? (
                            <InputComponent
                              type="number"
                              name="amount"
                              placeholder="Кол-во"
                              value={pos.amount}
                              setValue={onChangePos}
                              currentIndex={{ index: index, ind: ind }}
                            />
                          ) : (
                            new Intl.NumberFormat("ru-RU").format(pos.amount)
                          )
                        }
                      </td>
                      <td className={cl.meters}>
                        {
                          isEditing ? (
                            <InputComponent
                              type="text"
                              name="address"
                              autocomplete="off"
                              placeholder="Адрес"
                              value={pos.address}
                              setValue={onChangePos}
                              currentIndex={{ index: index, ind: ind }}
                            />
                          ) : (
                            pos.address
                          )
                        }
                      </td>

                      {
                        isEditing && getUser().accessLevel > 2 && (
                          <td className="iconed">
                            <span
                              className="roundIcon material-icons"
                              onClick={() =>
                                deleteLine({ index: index, ind: ind })
                              }
                              title="Удалить строку"
                            >
                              delete_outline
                            </span>
                          </td>
                        )
                      }
                    </tr>
                  ))}
                </Fragment>
              ))}
            <tr style={{ height: "300px" }} />
          </tbody>
        </table>
      </div>
      <Filter
        isOpen={isFilterOpen}
        data={plants}
        setData={setPlants}
        currentSort={sorted}
        sortFn={sortPlants}
        groups={groups}
        addressList={addressList}
        plants={plantsNames}
        articles={plantsArticles}
        multiple={true}
      />
    </>
  );
};

// export async function getServerSideProps({ params }) {
//   const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";

//   const headerResponse = await axios.get(
//     "fields/getFieldHead/" + params.fieldId + "?map_id=" + params.mapId,
//     {
//       headers: {
//         Authorization: apiToken,
//       },
//     }
//   );
//   const header = headerResponse.data.result;

//   const dataResponse = await axios.get(
//     "fields/getField/" + params.fieldId + "?map_id=" + params.mapId,
//     {
//       headers: {
//         Authorization: apiToken,
//       },
//     }
//   );
//   const data = dataResponse.data.result;

//   // Запрос всех групп
//   const groupsResponse = await axios.get("businessRu/getGroups", {
//     headers: {
//       Authorization: apiToken,
//     },
//   });
//   const groups = groupsResponse.data.result;

//   // Список адресов для фильтра
//   const addressList = [];
//   for (let i = 1; i <= header.rows; i++) {
//     addressList.push({
//       name: header.name + "." + i,
//       value: header.name + "." + i,
//     });
//   }
//   addressList.push({ name: header.name + ".-", value: header.name + ".-" });

//   return { props: { header, data, groups, addressList } };
// }

export default FieldId;
